<div #metricHeader>
  <div *ngIf="bundle.dataEntry">
    <div *ngFor="let choice of bundle.choices" style="font-size: 12px; font-family: Arial;  ">
      <jqxCheckBox #checkBox
                   [width]="bundle.objW"
                   [height]="25"
                   [theme]="'material-green'"
                   [checked]="choice.SELECTED"
                   [groupName]="choice.STUDYQUESTIONID"
                   (onChange)='onChange_jqxCheckBox($event)'>
        <span>{{choice.QUESTIONRESULT_USER}}</span>
      </jqxCheckBox>
    </div>
  </div>

  <div *ngIf="!bundle.dataEntry">
    <div *ngFor="let choice of bundle.choices; index as i" style="clear:left; font-size: 12px; font-family: Arial;  ">

      <jqxCheckBox #checkBox
                   [width]="bundle.objW"
                   [height]="25"
                   [theme]="'material-green'"
                   [checked]="choice.SELECTED"
                   [groupName]="choice.STUDYQUESTIONID"
                   [disabled]="'true'">

        <span style="float:left; ">{{choice.QUESTIONRESULT_USER}}</span>

        <!--<div *ngIf="i == 0" style ="float:left; padding-left:4px; " >
          <jqxTooltip
            [position]="'left'"
            [name]="'movieTooltip'"
            [autoHideDelay]="30000"
            [content]="bundle.Tooltip01"
            [width]="300"
            [opacity]="1.0"
            [showArrow]="false"
            >

            <img height = 10 style = "position:relative; top:-5px; width: auto; " src="assets/infoBtn.png" />

          </jqxTooltip>
        </div>-->

      </jqxCheckBox>
    </div>
  </div>
</div>
