import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlCompaniesService } from '../ll-companies.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { LlModelsService } from '../ll-models.service';

@Component({
  selector: 'll-company-switch',
  templateUrl: './ll-company-switch.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlCompanySwitchComponent implements OnInit {


  @ViewChild('mainDiv_ll_company_switch') mainDiv: ElementRef;
  @ViewChild('horzTabs_ll_company_switch') public horzTabs: ElementRef;


  constructor(
    private LlCompaniesService: LlCompaniesService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,
    private LlModelsService: LlModelsService,
  ) { }

  bundle = {
    showHorzTabs: false,
    showRemaining: false,    
    function: '',
    LlCompaniesService: this.LlCompaniesService,
    LlScrollpaneService: this.LlScrollpaneService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 20,
    remainingW: 0,
    remainingH: 0,
    showTab: true,
    horzTabsY: 20,
    hTabW: 100,
    hTabH: 25,
    hTabIndent: 100,
    hTabIndx: 0,
    horzTabs: [],
    horzTabs_02: [],
    canvasHT: {},
    ctxHT: {},
    activeHorzTab:'',
  }

  ngOnInit() { }
  ngOnDestroy() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.showHorzTabs = false;
    this.bundle.showRemaining = false;

    this.bundle.function = this.mainDiv.nativeElement.parentElement.id;

    ///console.log(this.bundle.function)

    setTimeout(() => {
      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      setTimeout(() => {
        this.bundle.remainingX = 0;
        this.bundle.remainingY = this.bundle.horzTabsY + this.bundle.hTabH + 3
        this.bundle.remainingW = this.bundle.parentW;;
        this.bundle.remainingH = this.bundle.parentH - this.bundle.remainingY;

        var canvasHT: HTMLCanvasElement = this.horzTabs.nativeElement;
        this.bundle.canvasHT = canvasHT
        this.bundle.ctxHT = canvasHT.getContext('2d');

        this.getData()
      });
    });
  }

  getData() {

    let companyId = this.LlCompaniesService.getActiveCompanyId();

    var model = this.LlModelsService.getActiveCategory();

    this.bundle.horzTabs.splice(0);

    if (this.bundle.function == "from_ll_models_sp") {

      if (this.LlPermissionsService.check("viewModels")
        || this.LlPermissionsService.check("EditModels")) {

        this.bundle.horzTabs.push({ lbl: "Models" });
      }

      if (this.LlPermissionsService.check("addEditUser")) { 
        this.bundle.horzTabs.push({ lbl: "Users" });
      }

      if (this.LlPermissionsService.check("addEditCompany")) { 
        this.bundle.horzTabs.push({ lbl: "Company" });
      }

      if (this.LlPermissionsService.check("viewLogInRecords")) {
        this.bundle.horzTabs.push({ lbl: "Logins" });
      }

      if (this.LlPermissionsService.check("viewEmailRecords")) {
        this.bundle.horzTabs.push({ lbl: "Emails" });
      }
       

      if (this.LlPermissionsService.check("adminEventTrail")) {
        this.bundle.horzTabs.push({ lbl: "Entry" });
      }

      

      this.bundle.activeHorzTab = "Models";

    } else {

      this.bundle.hTabIndent = 30;

      if (this.LlPermissionsService.check("addEditUser")) {
        this.bundle.horzTabs.push({ lbl: "Users" });
      }

      this.bundle.activeHorzTab = "Users";

      if (companyId != 0) {
        if (this.LlPermissionsService.check("viewModels")
          || this.LlPermissionsService.check("EditModels")) {

          this.bundle.horzTabs.push({ lbl: "Models" });
        }
        if (this.LlPermissionsService.check("addEditCompany")) {
          this.bundle.horzTabs.push({ lbl: "Company" });
        }
        if (this.LlPermissionsService.check("viewLogInRecords")) {
          this.bundle.horzTabs.push({ lbl: "Logins" });
        }
        if (this.LlPermissionsService.check("viewEmailRecords")) {
          this.bundle.horzTabs.push({ lbl: "Emails" });
        }

        if (this.LlPermissionsService.check("adminEventTrail")) {
          this.bundle.horzTabs.push({ lbl: "Entry" });
        }

      } else {
        this.bundle.hTabIndx = 0;///users
      }
    }

    

    this.LlScrollpaneService.paintHorzTab(horzTabSelected, this.bundle.horzTabs, this.bundle.canvasHT, this.bundle.ctxHT, this.bundle);

    function horzTabSelected(indx) {}

    this.bundle.showHorzTabs = true;
    this.bundle.showRemaining = true;
    //}
  }

  
}


