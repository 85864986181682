import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-info-request-widget-long-text',
  templateUrl: './ll-info-request-widget-long-text.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlInfoRequestWidgetLongTextComponent {
  @ViewChild('textAreaReference') myInput: jqxTextAreaComponent;
  @ViewChild('metricHeader') metricHeader: ElementRef;

  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
  ) { }

  bundle = {
    showObj: false,
    dataEntry: false,
    keyUp: false,
    STUDYQUESTIONID: 0,    
    charLimit: 0,
    origionalVal: "",
    QUESTIONRESULT_USER: '',
    objW: 0,
    objH: 100,
    Tooltip01: 'not Found',
  }

  ngOnInit() {
  }

  eventSubscription_renewQuestion: any;

  ngOnDestroy() {
    this.eventSubscription_renewQuestion.unsubscribe();
  }

  ngAfterViewInit() {
    this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

      if (this.bundle.STUDYQUESTIONID == STUDYQUESTIONID) {
        this.setVars();
      }

    });

    this.setVars();
  }

  setVars() {

      this.bundle.STUDYQUESTIONID = this.metricHeader.nativeElement.parentElement.id;
      let record = this.LlInfoRequestsService.getSelectStudyQuestion(this.bundle.STUDYQUESTIONID);      

      setTimeout(() => {

        this.bundle.objW = this.metricHeader.nativeElement.parentElement.parentElement.clientWidth;
        this.bundle.charLimit = record.QUESTIONCHARACTERLIMIT;
        this.bundle.dataEntry = record.dataEntry;
        this.bundle.origionalVal = record.QUESTIONRESULT_USER;

        if (this.bundle.dataEntry == true) {

          this.myInput.val(this.bundle.origionalVal);

          this.bundle.Tooltip01 = "Type content into this box and EITHER move mouse pointer out of the text box OR hit the ‘TAB’ key to save.";
          
        } else {
          this.bundle.Tooltip01 = "Data entry is disabled for this question.  Either the question has been locked or signed, is read only, deleted (red), or you do not have data entry permission.";
        }

        this.bundle.showObj = true;

      },0);
   
  }

  onKeyUp(event) {

    this.bundle.keyUp = true;

    if (event.keyCode == 13) { ///Enter
      this.myInput.val(removeCarriageReturn(this.myInput.val()));
      this.checkEnter();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.checkEnter();
    }
  }

  mouseLeave() {
    this.checkEnter();
  }

  checkEnter() {
    let val = this.myInput.val();    

    if (this.bundle.keyUp == true && this.bundle.dataEntry == true && val != this.bundle.origionalVal) {

      this.bundle.keyUp = false;

      this.bundle.QUESTIONRESULT_USER = val;

      this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID)
      this.LlInfoRequestsService.NG_LL_infoReq_subjectQuestions_updateValue("STD", this.bundle)

    } 
  }
}

