import { Injectable } from '@angular/core';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LlStatusCirclesService {

  constructor(
    private ServerService: ServerService,
    private UserService: UserService,
  ) { }

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    STUDYID_SYS: 0,
    CONTACTROLEID: 0,
    SYSTEMROLEID: 0,
    SUBJECTID_SYS: 0,
    STUDYPROVIDERID: 0,
    SYSTEMPRODUCTID: 0,
    QUESTIONSTAGEID: 0,
    VISITCATEGORY: "Visit"
  }

  bundle_local = {
    ServerService: this.ServerService,
    vars: this.vars,
    records: [],
    inBundle: []
  }

  NG_utility_getActiveQCStages(callback01, contactRole, inBundle) {
      
    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_getActiveQCStages";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      bundle_local.records = arr; 

  

        ///NOTE: Added in reverse order so last ( QUESTIONSTAGEID = 1) is on top
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 1, QUESTIONSTAGEID: "2", QUESTIONSTAGENAME: "Entered", PENDINGLABEL: "Verification" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 1, QUESTIONSTAGEID: "1", QUESTIONSTAGENAME: "Not Entered", PENDINGLABEL: "Entry" });

        bundle_local.records.unshift({ SYSTEMPRODUCTID: 2, QUESTIONSTAGEID: "2", QUESTIONSTAGENAME: "Entered", PENDINGLABEL: "Verification" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 2, QUESTIONSTAGEID: "1", QUESTIONSTAGENAME: "Not Entered", PENDINGLABEL: "Entry" });

        //pushed so at the end
        bundle_local.records.push({ SYSTEMPRODUCTID: 2, QUESTIONSTAGEID: "0", QUESTIONSTAGENAME: "All", PENDINGLABEL: "All" });

        ///eTMF
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 3, QUESTIONSTAGEID: "2", QUESTIONSTAGENAME: "Entered", PENDINGLABEL: "Verification" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 3, QUESTIONSTAGEID: "1", QUESTIONSTAGENAME: "Not Entered", PENDINGLABEL: "Upload" });

        bundle_local.records.unshift({ SYSTEMPRODUCTID: 4, QUESTIONSTAGEID: "2", QUESTIONSTAGENAME: "Entered", PENDINGLABEL: "Verification" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 4, QUESTIONSTAGEID: "1", QUESTIONSTAGENAME: "Not Entered", PENDINGLABEL: "Entry" });

        bundle_local.records.unshift({ SYSTEMPRODUCTID: 18, QUESTIONSTAGEID: "2", QUESTIONSTAGENAME: "Entered", PENDINGLABEL: "Review" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 18, QUESTIONSTAGEID: "1", QUESTIONSTAGENAME: "Not Entered", PENDINGLABEL: "Entry" });


        bundle_local.records.unshift({ SYSTEMPRODUCTID: 21, QUESTIONSTAGEID: "4", QUESTIONSTAGENAME: "All", PENDINGLABEL: "All" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 21, QUESTIONSTAGEID: "3", QUESTIONSTAGENAME: "Disregard", PENDINGLABEL: "Disregard" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 21, QUESTIONSTAGEID: "4", QUESTIONSTAGENAME: "Archive", PENDINGLABEL: "Archive" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 21, QUESTIONSTAGEID: "2", QUESTIONSTAGENAME: "Complete", PENDINGLABEL: "Complete" });
        bundle_local.records.unshift({ SYSTEMPRODUCTID: 21, QUESTIONSTAGEID: "1", QUESTIONSTAGENAME: "Active", PENDINGLABEL: "Active" });


    

      bundle_local.records.push({ SYSTEMPRODUCTID: -1, QUESTIONSTAGEID: "14", QUESTIONSTAGENAME: "Open", PENDINGLABEL: "Open", image: 'statusCircle_query.png' });
      bundle_local.records.push({ SYSTEMPRODUCTID: -1, QUESTIONSTAGEID: "15", QUESTIONSTAGENAME: "Answered", PENDINGLABEL: "Answered", image: 'statusCircle_answered.png' });
      bundle_local.records.push({ SYSTEMPRODUCTID: -1, QUESTIONSTAGEID: "17", QUESTIONSTAGENAME: "Closed", PENDINGLABEL: "Close", image: 'statusCircle_closed.png' });
     


      callback01(contactRole, inBundle)
    }

  }

  get(SYSTEMPRODUCTID) {

    ///console.log(SYSTEMPRODUCTID)

    let records = [];

    for (let i = 0; i < this.bundle_local.records.length; i++) {
      if (this.bundle_local.records[i].SYSTEMPRODUCTID == SYSTEMPRODUCTID) {
        records[records.length] = this.bundle_local.records[i];
      }
    }

    return records;
  }

  getByGroup(group) {
    let records = [];

    for (let i = 0; i < this.bundle_local.records.length; i++) {
      if (this.bundle_local.records[i].group == group) {
        records[records.length] = this.bundle_local.records[i];
      }
    }

    return records;
  }




  getPendingStatusIdById(ID) {

    for (let i = 0; i < this.bundle_local.records.length; i++) {
      if (ID == this.bundle_local.records[i].QUESTIONSTAGEID) {
        return this.bundle_local.records[i];
      }

    }

  }

  getPendingStatusIdByName(name) {

    for (let i = 0; i < this.bundle_local.records.length; i++) {
      if (name.toLowerCase() === this.bundle_local.records[i].pending.toLowerCase()) {
        return this.bundle_local.records[i].QUESTIONSTAGEID;
      }

    }

  }


  returnChoice(name: String) {
    for (let i = 0; i < this.bundle_local.records.length; i++) {

      if (this.bundle_local.records[i].name == name) {
        return this.bundle_local.records[i];
      }
    }
  }

}
