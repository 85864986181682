<div #metricHeader >

  <div *ngIf="bundle.showObj" [style.width.px]= "bundle.objW">
    <ll-info-request-entry-question id={{bundle.STUDYQUESTIONID}}></ll-info-request-entry-question>
  </div>

  <div style="clear: left; padding-left: 25px; padding-top: 5px; ">

    <!--DATA ENTRY FALSE-->
    <div [hidden]="bundle.dataEntry" [style.width.px]="bundle.objW - 65" [style.height.px]="bundle.objH"
         style="float:left;
        border-style: solid;
        border-color:lightgray;
        background-color:lightyellow;
        border-radius: 3px;

        text-indent: 5px; font-size: 13px; font-family: Arial;

        ">
      {{bundle.origionalVal}}

    </div>

    <!--DATA ENTRY TRUE-->
    <div [hidden]="!bundle.dataEntry" (keyup)="onKeyUp($event)" style="float: left; background-color: white; ">

      <jqxTextArea #textAreaReference
                   [roundedCorners]="true"
                   [height]="bundle.objH"
                   [width]="this.bundle.objW - 65"
                   [maxLength]="bundle.charLimit"
                   (mouseleave)="mouseLeave()">
      </jqxTextArea>
    </div>

    <!--TOOLTIP-->
    <div [hidden]="!bundle.dataEntry" style="float:left; padding-left:4px; ">
      <jqxTooltip [position]="'left'"
                  [name]="'movieTooltip'"
                  [autoHideDelay]="30000"
                  [content]="bundle.Tooltip01"
                  [width]="300"
                  [opacity]="1.0"
                  [showArrow]="false">

        <img height=10 style="position:relative; top:-5px; width: auto; " src="assets/infoBtn.png" />

      </jqxTooltip>
    </div>

  </div>

  <div style="clear: left; border-bottom: 1px solid black; padding-top:5px"></div>

</div>
