<div #metricHeader>


  <div *ngIf="bundle.showObj" [style.width.px]="bundle.questionW">
    <ll-info-request-entry-question id={{bundle.STUDYQUESTIONID}}></ll-info-request-entry-question>
  </div>

  <!--DATA ENTRY FALSE-->
  <!--<div *ngIf="!bundle.dataEntry"
       [style.width.px]="bundle.valueRow01W"
       style="float:left;
             height:25px;
             text-align: right;
             vertical-align: middle;
             font-size: 13px;
             font-family: Arial;
             border-style: solid;
             border-color:lightgray;
             border-width: 1px;
             background-color:lightyellow;
             border-radius: 1px;
             ">

    <div style="padding-right: 3px;">{{bundle.orgVal}}</div>
  </div>--->

  <div *ngIf="!bundle.dataEntry" style="float:left; text-align: right;">
    
      <input type="text" disabled autocomplete="off" #inputField
             id={{bundle.divId}}
             value={{bundle.orgVal}}
             (keydown)="onKeyDown($event)"
             (focusout)="mouseLeave($event)"
             (mouseout)="mouseLeave($event)"
             [style.width.px]="bundle.valueRow01W"
             style="float:left;
               height:25px;
               text-align: right;
               vertical-align: middle;
               font-size: 13px;
               font-family: Arial;
               border-style: solid;
               border-color:lightgray;
               background-color:lightyellow;
               border-width: 1px;
               border-radius: 1px;
             ">

    
  </div>

  <!--DATA ENTRY TRUE-->
  <div *ngIf="bundle.dataEntry" style="float:left; text-align: right;">
    <form>
      <input type="text" autocomplete="off" #inputField
             id={{bundle.divId}}
             value={{bundle.orgVal}}
             (keydown)="onKeyDown($event)"
             (focusout)="mouseLeave($event)"
             (mouseout)="mouseLeave($event)"
             [style.width.px]="bundle.valueRow01W"
             style="float:left;
                height:25px;
                text-align: right;
                vertical-align: middle;
                font-size: 13px;
                font-family: Arial;
                border-style: solid;
                border-color:lightgray;
                border-width: 1px;
                border-radius: 1px;
              ">

    </form>
  </div>

  <!--UNIT-->
  <div class="txtSmall" style="float:left; padding-left:10px; ">
    {{bundle.questionUnit}}
  </div>

  <!--TOOLTIP-->
  <div *ngIf="bundle.dataEntry" id='Tooltip01' style="float:left; padding-left:4px; ">
    <jqxTooltip [position]="'left'"
                [name]="'movieTooltip'"
                [autoHideDelay]="30000"
                [content]="bundle.Tooltip01"
                [width]="300"
                [opacity]="1.0"
                [showArrow]="false">

      <img height=10 style="position:relative; top:-5px; width: auto; " src="assets/infoBtn.png" />

    </jqxTooltip>
  </div>

  <div style="clear: left; border-bottom: 1px solid black; padding-top:5px"></div>

</div>

