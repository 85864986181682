<div #mainDiv_ll_model_report_bbc_one>

  <div [hidden]="!bundle.showDisplay"
       [style.top.px]="bundle.displayY"
       [style.left.px]="bundle.displayX" style="position: absolute;">

    <jqxPanel [width]="this.bundle.displayW"
              [height]="this.bundle.displayH"
              [autoUpdate]="true"
              [scrollBarSize]="15"
              style="position: absolute; outline: none;  border: 2px solid #ccc;">

      <div style="font-family: Arial; padding-left:20px; padding-top:20px">
        <div style="float: left;">
          <!--<img height=25 style="width: auto;" [src]='bundle.imageSource'>-->
          <img height={{bundle.imageHeight}} style="width: auto;" [src]='bundle.imageSource'>
        </div>

        <div style="float: left; padding-left:40px ">
          <div style="font-size: 16px; font-weight: bold; ">
            {{bundle.BBC_label}}
          </div>

          <div style="font-size: 14px; padding-top:10px">
            <div>
              <div style="float: left; width:150px ">
                Data Through Date:
              </div>
              <div>
                {{bundle.date_calc}}
              </div>
            </div>

            <div>
              <div style="float: left; width:150px ">
                Report Date:
              </div>
              <div>
                {{bundle.date_now}}
              </div>
            </div>


          </div>

        </div>

        <div style="clear: left; padding-top:20px ">

          <div #mainColumn_1 style="float: left;">
            <div>
              <div style="font-size: 14px; font-weight: bold; ">
                {{bundle.COMPANYNAME}}
              </div>

              <div style="font-size: 12px; font-weight: normal; ">
                <div>
                  Loan ID: {{bundle.MODELNAME}}
                </div>

              </div>
            </div>

            <div style="padding-top:20px">

              <div style="padding-top:10px; font-size: 12px; font-weight: bold; ">
                Locations / Facilities
              </div>


      <tr *ngFor="let location of bundle.locations">
        <div [style.width.px]="bundle.mainColW_1" style="font-size: 11px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          {{location.LOCATIONNAME}}
        </div>
      </tr>


      <div [style.width.px]="bundle.mainColW_1" style="padding-top:20px; font-size: 12px; font-weight: normal; word-wrap: break-word;">
        {{bundle.LOANLANGUAGE}}
      </div>

      <div style="padding-top:20px; font-size: 12px;  ">

        <div>
          <div style="width:127px; float: left; font-weight: bold; text-align: right; white-space: nowrap;">
            Authorized Signature:

          </div>

          <div style="float: left;">
            _______________________________
          </div>
        </div>

        <div style="padding-top:30px;">

          <div style="width:127px; float: left; font-weight: bold; text-align: right">
            Title:
          </div>

          <div style="float: left;">
            _______________________________
          </div>
        </div>

        <div style="padding-top:30px;">

          <div style="width:127px; float: left; font-weight: bold; text-align: right">
            Date:
          </div>

          <div style="float: left;">
            _______________________________
          </div>
        </div>

      </div>

  </div>
</div>

<div #mainColumn_2 style="float: left; padding-left:20px ">

  <tr *ngFor="let element of bundle.elements " style="font-size: 12px; font-weight: normal; ">

    <div style="clear:left; font-size: 11px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">

      <div style="float:left;">

        <div [style.width.px]="bundle.mainColW_2_1" style="float:left;">
          {{element.LABEL}}
        </div>

        <div *ngFor="let record of bundle.records;">


          <div [style.width.px]="bundle.mainColW_2 - bundle.mainColW_2_1 - 6">
            <input *ngIf="element.input == true" type="text" (keyup)="calculations($event)" [id]="element.ELEMENT" value={{record[element.ELEMENT]}}
                   style="float:right; width: 100px; text-align: right;">

          </div>


          <div *ngIf="element.input == false" [id]="element.ELEMENT" [style.padding-left.px]=10 [style.width.px]="bundle.mainColW_2 - bundle.mainColW_2_1 - 10"
               style="text-align: right;">
            {{record[element.ELEMENT]}}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="element.UNDERLINE == 1"
         [style.width.px]="bundle.mainColW_2 - bundle.mainColW_2_1 - 10"
         [style.height.px]="20"
         style="clear:left; text-align: right; white-space: nowrap; overflow: hidden;">
      _____________
    </div>

    <!--<input type="text" id="fname" name="fname" value="John"> -->



  </tr>
</div>
                </div>
            </div>

        </jqxPanel>

<div id=bottomLine [style.left.px]="bundle.pageW" [style.top.px]="this.bundle.displayH + 10" style="position: absolute;">

  <div class="wordBtnGroup">
    <!--<img class = "btnIcon" height = 15 style = "width: auto;"  src="assets/icon_plus.png" alt="" (click)="noAction()">-->
    <div class="wordBtn" (click)="getBBCPDF()">Print</div>
  </div>
</div>
    </div>

</div>
