
<div #mainDiv_ll_model_displayData>

  <div [hidden]="!bundle.showDisplay">

    <!--GRAPH-->
    <div *ngIf="bundle.graphArea_show"
         [style.top.px]="0"
         [style.left.px]="0"
         [style.width.px]="bundle.graphAreaW"
         [style.height.px]="bundle.graphAreaH"
         style="position: absolute;">
      <ll-model-graph-one></ll-model-graph-one>
    </div>
    
    <!--DISPLAY AREA-->
    <div [style.left.px]="bundle.displayAreaX"
         [style.top.px]="bundle.displayAreaY"
         style="position: absolute;">


      <jqxPanel #displayArea *ngIf="bundle.displayArea_show"
                [width]="bundle.parentW"
                [height]="bundle.displayAreaH"
                [autoUpdate]="true"
                style="position: absolute; white-space: nowrap; outline: none;  border-bottom: 2px solid #ccc;">

        <!--COLUMN DATE LABELS-->
        <div *ngFor="let record of bundle.records;">

          <div *ngIf="record.COLYN == 'Y' "
               [style.left.px]="record.colX"
               [style.width.px]="bundle.dateColumnW"
               style="position: absolute;">

            <div [style.top.px]="0"
                 [style.left.px]=""
                 [style.width.px]="bundle.dateColumnW"
                 style="position: absolute;">

              <!--<img width = 100 height = 20 src="assets/icon_bracket.png"  >-->

              <div [style.left.px]="0"
                   [style.top.px]="10"
                   [style.width.px]="bundle.dateColumnW"
                   style="position: absolute;
                            text-align: center;
                            font-size: 12px;
                            font-weight: bold;
                            font-family: Arial;
                            color:black; ">

                {{record.PERIODENDDATE_DATE}}
              </div>
            </div>
          </div>
        </div>

        <!--METRIC ROW LABLES - LOOP THROUGH FORMS-->
        <div *ngFor="let form of bundle.forms; index as i" style="white-space: nowrap;">

          <div [style.height.px]="10"></div>

          <div class="arLoanForm"
               [style.width.px]="bundle.displayAreaW"
               [style.height.px]="20"
               style="border-bottom: 1px solid white; ">

            {{form.FORMNAME}}

          </div>

          <!--LOOP THROUGH AND FILTER OUT FORM ELEMENTS-->
        <tr *ngFor="let element of bundle.elements | visitCategory:{FORMID: form.FORMID}" style="clear:left; white-space:nowrap;">

          <!--METRIC ROWS-->
          <div [id]="element.ELEMENT"
               [style.left.px]="0"
               [style.width.px]="bundle.displayAreaW"
               [style.height.px]="20"
               style=" border-bottom: 1px solid white;  ">

            <!--METRIC LABELS-->
            <div style="float: left;  ">

              <jqxTooltip [position]="'right'"
                          [name]="'movieTooltip'"
                          [autoHideDelay]="30000"
                          [content]="element.DESCRIPTION"
                          [opacity]="1.0"
                          [width]="400">

                <img class="infoBtn" src="assets/infoBtn.png" />

              </jqxTooltip>

            </div>

            <div style="float: left; padding-left:4px ">
              <img [id]="element.ELEMENT" src="assets/icon_chart_bar.png" alt="" (click)="selectElement($event)" style="height:12px; width:auto;cursor:pointer;">
            </div>

            <div class="arLoanLabel" style="float: left; white-space:nowrap;">
              {{element.LABEL}}
            </div>

            <!--COLUMN VALUES-->
            <div *ngFor="let record of bundle.records;">

              <div *ngIf="record.COLYN == 'Y' "
                   [style.left.px]="record.colX"
                   [style.width.px]="bundle.dateColumnW"
                   style="position: absolute;">

                <div *ngIf="element.UNDERLINE == 1"
                     [style.left.px]="-20"
                     [style.top.px]="2"
                     [style.width.px]="bundle.dateColumnW"
                     style="position: absolute;
                                        text-align: right;
                                        font-size: 12px;
                                        font-family: Arial;
                                        color:black;
                                        text-decoration: overline;
                                        ">

                  {{record[element.ELEMENT]}}



                </div>

                <div *ngIf="element.UNDERLINE != 1"
                     [style.left.px]="-20"
                     [style.top.px]="2"
                     [style.width.px]="bundle.dateColumnW"
                     style="position: absolute;
                                        text-align: right;
                                        font-size: 12px;
                                        font-family: Arial;
                                        color:black; ">

                  {{record[element.ELEMENT]}}

                </div>

              </div>
            </div>

            <!--ALARMS-->
            <!--<div [style.left.px]="bundle.rightColsX + 30"
                 [style.width.px]="bundle.rightColsW - 30"
                 style="position: absolute;">


              <div *ngIf="element.TRGVALUE == 0 && element.CANNOTICEYN == 'Y'" style="float: left; white-space: nowrap; padding-top: 4px;" [style.width.px]="bundle.rightColsW - 30 ">
                <div [id]="'AM' + element.ELEMENT" class="wordBtn_11" (click)="addEditAlarm($event, 'add')">Notice</div>
              </div>

              <div *ngIf="element.TRGVALUE != 0" style="float: left; white-space: nowrap; padding-top: 4px;" [style.width.px]="bundle.rightColsW">
                <img src="assets/icon_alarmBell.png" style="float: left; height:12px; width:auto;">


                <img *ngIf="element.HIGHLOW == 'H'" src="assets/icon_arrowUp.png" style="float: left; height:11px; width:auto;">

                <div style="float: left; padding-top:2px">
                  <img *ngIf="element.HIGHLOW == 'L'" src="assets/icon_arrowDown.png" style="float: left; height:11px; width:auto;">
                </div>

                <div style="float: left;
                                            padding-left:3px;
                                            text-align: right;
                                            font-size: 11px;
                                            font-family: Arial;
                                            color:black; ">

                  {{element.TRGVALUE}}

                </div>

                <img [id]="'AM' + element.ELEMENT" class="btnIcon" src="assets/icon_gear.png" (click)="addEditAlarm($event, 'edit')" style="float: left; padding-left:3px; height:12px; width:auto;">

              </div>

            </div>--->

          </div>
        </tr>


    </div>

    </jqxPanel>




  </div>

  <!--BOTTOM-->
  <div [style.left.px]="bundle.displayAreaX"
       [style.top.px]="bundle.displayAreaY + bundle.displayAreaH + 10"
       style="position: absolute;">

    <div class="wordBtn_11" (click)="downloadCSVReport()">Download CSV Report</div>
  </div>



</div>
