<div #mainDiv_email_routine>

  <div [hidden]="!bundle.showObj" style="padding-top:20px;  ">

    <div>
      <div style="float: left; ">
        <img height=30 style="width: auto;" src="assets/icon_schedule.png">
      </div>

      <div class="txtBold_16" style="float: left; padding-left:20px; padding-top:5px;  ">
        Configure Email Routine.
      </div>
    </div>



    <div [style.top.px]="bundle.SPY" style="position: absolute;">

      <jqxPanel #SP_email_Routines_scrollpane style="background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
                [autoUpdate]="true"
                [width]="bundle.SPW"
                [height]="bundle.SPH">

        

          <!--Active Status-->
          <div style="clear: left; padding-left:15px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="125" style="float: left; Padding-top:3px;  ">
              Active Status:
            </div>

            <div style="float: left; padding-left:0px;">

              <div *ngFor="let activeChoice of bundle.statusChoices" style="float:left; height:30px; font-size: 12px; font-family: Arial;  ">
                <jqxRadioButton [width]="100"
                                [height]="25"
                                [theme]="'material-green'"
                                [groupName]="'activeChoice'"
                                [checked]="activeChoice.SELECTED"
                                (onChange)='selectStatus($event)'>

                  <span>{{activeChoice.choice}}</span>
                </jqxRadioButton>
              </div>
            </div>
          </div>

          <!--Routine Name-->
          <div style="clear: left; padding-left: 15px; padding-top: 5px;">



            <div style="float:left; padding-left:0px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="125" style="float: left; ">
                Routine Name:
              </div>

              <div style="float:left; padding-left:0px">
                <jqxTextArea #text_routineName [width]="400" [height]="20"> </jqxTextArea>
              </div>
            </div>
          </div>

          <!--Email Template-->
          <div *ngIf="bundle.showEmailTemplate" style="clear: left; padding-left: 15px; padding-top: 5px;">



            <div style="float:left; padding-left:0px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="125" style="float: left; ">
                Email Template:
              </div>

              <jqxDropDownList #dropDown_emailTemplate style="float: left;"
                               [theme]="'material-green'"
                               [width]="400"
                               [height]="23"
                               [source]="bundle.emailTemplateChoices"
                               [dropDownHorizontalAlignment]="'left'"
                               [dropDownVerticalAlignment]="'bottom'"
                               [dropDownWidth]="400"
                               [disabled]='false'
                               [selectedIndex]="bundle.templateSelectIndex"
                               (onSelect)="selectTemplate($event)">

              </jqxDropDownList>

            </div>
          </div>

          <!--this DIV added for timing-wait for template choices-->
          <div [style.top.px]="110" style="position: absolute;">

            <!--filter-->
            <div style="clear: left; padding-left:15px; padding-top:8px;">

              <div class="txtNorm" [style.width.px]="125" style="float: left; padding-top:3px;  ">
                Filter:
              </div>

              <div [style.width.px]="400" style="float: left; padding-left:0px;">

                <div *ngFor="let distributionChoice of bundle.distributionArr" style="float:left; height:30px; font-size: 12px; font-family: Arial;  ">
                  <jqxRadioButton [width]="400"
                                  [height]="25"
                                  [theme]="'material-green'"
                                  [groupName]="'distributionChoiceGrp'"
                                  [checked]="distributionChoice.SELECTED"
                                  (onChange)='selectDistribution($event)'>

                    <span>{{distributionChoice.choice}}</span>
                  </jqxRadioButton>
                </div>
              </div>
            </div>

            <!--Start Date-->
            <div style="clear: left; padding-left:15px; padding-top:10px; ">

              <div>
                <div style="float: left; ">
                  <!--<img height = 8 style = "width: auto;" src="assets/icon_asterisk.png" >-->
                </div>

                <div style="float:left; padding-left:0px; padding-top:5px;">

                  <div class="txtNorm" [style.width.px]="125" style="float: left;">
                    Start Date:
                  </div>

                  <div style="float:left; padding-left:0px">
                    <jqxDateTimeInput #date_start [width]="120" [height]="20" [formatString]="'d'"> </jqxDateTimeInput>
                  </div>
                </div>
              </div>


              <div style="float: left; padding-left:20px; ">
                <div style="float: left; ">
                  <!--<img height = 8 style = "width: auto;" src="assets/icon_asterisk.png" >-->
                </div>

                <div style="float:left; padding-left:5px; padding-top:5px;">

                  <div class="txtNorm" [style.width.px]="75" style="float: left;">
                    End Date:
                  </div>

                  <div style="float:left; padding-left:5px">
                    <jqxDateTimeInput #date_end [width]="120" [height]="20" [formatString]="'d'"> </jqxDateTimeInput>
                  </div>
                </div>
              </div>
            </div>

            <!--Frequency-->
            <div style="clear: left; padding-left:15px; padding-top:10px;">

              <div style="float: left; ">
                <!--<img height = 8 style = "width: auto;" src="assets/icon_asterisk.png" >-->
              </div>

              <div style="float:left; padding-left:0px; padding-top:5px;">

                <div class="txtNorm" [style.width.px]="125" style="float: left; ">
                  Frequency:
                </div>

                <div style="float: left; padding-left:0px;">

                  <div *ngFor="let freq of bundle.frequencyChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
                    <jqxRadioButton [width]="80"
                                    [height]="25"
                                    [theme]="'material-green'"
                                    [groupName]="'freqGroup'"
                                    [checked]="freq.SELECTED"
                                    (onChange)='selectFrequency($event)'>

                      <span>{{freq.choice}}</span>
                    </jqxRadioButton>
                  </div>
                </div>
              </div>
            </div>

            <!--onTheDay-->
            <div [hidden]="bundle.FREQUENCY != 'Monthly' && bundle.FREQUENCY != 'Weekly'" style="clear: left; padding-left:15px; padding-top:10px;">

              <div style="float: left; ">
                <!--<img height = 8 style = "width: auto;" src="assets/icon_asterisk.png" >-->
              </div>

              <div style="float:left; padding-left:0px; padding-top:5px;">

                <div style="float: left;">

                  <div class="txtNorm" *ngIf="bundle.FREQUENCY == 'Monthly'" [style.width.px]="125" style="float: left;">
                    On the
                  </div>

                  <div class="txtNorm" *ngIf="bundle.FREQUENCY == 'Weekly'" [style.width.px]="125" style="float: left;">
                    Each
                  </div>

                  <div [hidden]="bundle.FREQUENCY != 'Monthly'" style="float: left; padding-left:0px;">

                    <div *ngFor="let item of bundle.onTheDayArr" style="clear:left; font-size: 12px; font-family: Arial;  ">
                      <jqxRadioButton [width]="175"
                                      [height]="25"
                                      [theme]="'material-green'"
                                      [groupName]="'onTheDay'"
                                      [checked]="item.SELECTED"
                                      (onChange)='selectOnTheDay($event)'>

                        <span>{{item.choice}}</span>
                      </jqxRadioButton>
                    </div>
                  </div>
                </div>

                <div [hidden]="bundle.FREQUENCY != 'Weekly'" style="float: left; padding-left:0px; ">

                  <div *ngFor="let dayW of bundle.weekDayArr" style="clear:left; font-size: 12px; font-family: Arial;  ">
                    <jqxRadioButton [width]="100"
                                    [height]="25"
                                    [theme]="'material-green'"
                                    [groupName]="'day_weekly'"
                                    [checked]="dayW.SELECTED"
                                    (onChange)='selectDay($event)'>
                      <span>{{dayW.choice}}</span>

                    </jqxRadioButton>
                  </div>

                </div>

                <div [hidden]="bundle.FREQUENCY != 'Monthly'" style="float: left; padding-left:0px; ">
                 
                  <div [hidden]="bundle.ONTHEDAY == 'Specific-Day'">

                      <div *ngFor="let day of bundle.dayArr" style="clear:left; font-size: 12px; font-family: Arial;  ">
                        <jqxRadioButton [width]="100"
                                        [height]="25"
                                        [theme]="'material-green'"
                                        [groupName]="'day_monthly'"
                                        [checked]="day.SELECTED"
                                        (onChange)='selectDay($event)'>
                          <span>{{day.choice}}</span>

                        </jqxRadioButton>
                      </div>
                    </div>

                  <div [hidden]="bundle.ONTHEDAY != 'Specific-Day' ">

                    <div style="float: left;">
                      <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
                    </div>

                    <div style="float:left; padding-left:5px; padding-top:5px;">

                      <div class="txtNorm" [style.width.px]="40" style="float: left; ">
                        Day:
                      </div>

                      <div (keyup)="specificDay_onKeyUp($event)" style="float:left; padding-left:0px">
                        <jqxTextArea #text_specificDay [width]="50" [height]="20"> </jqxTextArea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
      </jqxPanel>


    </div>

    <div id=bottomLine [style.top.px]="bundle.spB" style="position:absolute; ">

      <div style="float: left; padding-left: 15px;">
        <div class="wordBtn" (click)="editRoutine()">Save Changes</div>
      </div>

      <div class="DQRAlarm" style="float: left; padding-left: 10px;">
        {{bundle.editMsg}}
      </div>



    </div>
  </div>
</div>
