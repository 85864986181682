import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { stringToNumber } from "../utility";
import { removeNumberCommas } from "../utility";
import { javaDate_to_YYYYMMDD } from "../utility-date";

@Component({
  selector: 'll-model-displaydata',
  templateUrl: './ll-model-displaydata.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelDisplaydataComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_displayData') private mainDiv: ElementRef;
  @ViewChild('displayArea') public displayArea: jqxPanelComponent;

  constructor(
    private LlModelsService: LlModelsService,
  ) { }

  bundle = {
    showDisplay: false,
    graphArea_show: false,
    displayArea_show: true,
    LlModelsService: this.LlModelsService,
    daysBack: 0,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    graphAreaY: 20,
    graphAreaX: 0,
    graphAreaH: 175,
    graphAreaW: 0,
    graphX: 10,
    graphY: 10,
    graphW: 0,
    graphH: 0,
    displayAreaX: 10,
    displayAreaY: 0,    
    displayAreaW: 0,
    displayAreaH: 0,
    metricLabelsW: 225,
    dateColumnW: 75,
    rightColsX: 0, 
    rightColsW: 0,
    elements: [],
    forms: [],
    records: [],

  }
   
  ngOnInit() { }

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.showDisplay = false;

    setTimeout(() => {

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth - 20;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
      
      this.bundle.graphAreaW = this.bundle.parentW - 5;
      this.bundle.graphH = this.bundle.graphAreaH - this.bundle.graphY + 20
      this.bundle.graphW = this.bundle.graphAreaW - this.bundle.graphX;
      this.bundle.displayAreaY = this.bundle.graphAreaY + this.bundle.graphAreaH - 20;
      this.bundle.displayAreaW = this.bundle.parentW - this.bundle.rightColsW - 20;
      this.bundle.displayAreaH = this.bundle.parentH - this.bundle.displayAreaY - 40;
      this.bundle.rightColsX = this.bundle.displayAreaW
      
      this.bundle.daysBack = Math.round(this.bundle.graphAreaW / 6)

      this.LlModelsService.setDaysBack(this.bundle.daysBack);

      this.getData()

    });

  }

  getData() {

    this.LlModelsService.NG_LL_model_AR_formElements_get(done01, this.bundle,);

    function done01(elements, bundle) {
      bundle.elements = elements;
      bundle.forms = bundle.LlModelsService.getForms();
      bundle.LlModelsService.NG_LL_model_AR_displayData_get(done02, bundle);
    }

    function done02(arr, bundle) {

      bundle.records = arr;

      setTimeout(() => {        
               
        let count = 0;

        for (let i = 0; i < bundle.records.length; i++) {

          for (let j = 0; j < bundle.elements.length; j++) {

            if (Number.isFinite(bundle.records[i][bundle.elements[j].ELEMENT]) == true) {

              let reformatedValue = stringToNumber(bundle.records[i][bundle.elements[j].ELEMENT], bundle.elements[j].DISPLAYFORMAT, bundle.elements[j].DECIMALPLACES);

              bundle.records[i][bundle.elements[j].ELEMENT] = reformatedValue;
            }
          }

          if (bundle.records[i].COLYN.toLowerCase() == "y") {
            bundle.records[i].colX = (count * 100) + bundle.metricLabelsW;
            count++
          }
        }

        setTimeout(() => {

          let activeELEMENT = bundle.LlModelsService.getActiveElement();

          if (document.getElementById(activeELEMENT.toString()) != null) {

            let element = document.getElementById(activeELEMENT.toString())
            element.className = 'rowHighlight_select'
          }

          setTimeout(() => {
            bundle.showDisplay = true;
            bundle.graphArea_show = true;
            
          });
        });

      });

    }

  }

  selectElement(event) {

    var target = event.target || event.srcElement || event.currentTarget;

    //console.log(target.id)

    if (target != null) {
      var idAttr = target.id;

      let activeELEMENT = idAttr;

      this.setActive(activeELEMENT)
    }



  }

  setActive(activeELEMENT) {

    this.bundle.graphArea_show = false;

    for (let j = 0; j < this.bundle.elements.length; j++) {
      if (document.getElementById(this.bundle.elements[j].ELEMENT.toString()) != null) {
        let element = document.getElementById(this.bundle.elements[j].ELEMENT.toString())
        element.className = 'rowHighlight_normal'

      }
    }

    for (let j = 0; j < this.bundle.elements.length; j++) {

      if (document.getElementById(this.bundle.elements[j].ELEMENT.toString()) != null) {
        let element = document.getElementById(this.bundle.elements[j].ELEMENT.toString())

        if (this.bundle.elements[j].ELEMENT == activeELEMENT) {
          element.className = 'rowHighlight_select'

          this.bundle.LlModelsService.setActiveElement_withElementId(this.bundle.elements[j].ELEMENTID);

          setTimeout(() => {
            this.bundle.graphArea_show = true;
          });

        }
      }
    }
  }

  addEditAlarm(event, action) {

    var target = event.target || event.srcElement || event.currentTarget;

    ///console.log(action)

    if (target != null) {
      var idAttr = target.id;

      let activeELEMENT = idAttr.substr(2, idAttr.length - 2);

      this.setActive(activeELEMENT)

      //this.LlModelsService.trigerLoanAlarmPU(action);
    }
  }

  editAlarm(event, action) {
  }

  downloadCSVReport() {

    //console.log("downloadCSVReport()")

    let label = this.LlModelsService.getExportFileName();
    //let activeFolder = this.LlModelsService.getActiveFolder();

    var n = new Date()
    let nowDate = javaDate_to_YYYYMMDD(n)

    let fileName = nowDate + "_" + label

    let csv = fileName + '\n';/// this.bundle.forms[0].FORMNAME +'\n';

    //add date row
    csv += 'Date,';

    for (let j = 0; j < this.bundle.records.length; j++) {
      csv += this.bundle.records[j].PERIODENDDATE_DATE

      if (j < this.bundle.records.length - 1) {
        csv += ','
      } else {
        csv = csv + '\n'
      }
    }

    for (let i = 0; i < this.bundle.forms.length; i++) {

      csv += this.bundle.forms[i].FORMNAME + '\n';

      ///loop through each element
      for (let j = 0; j < this.bundle.elements.length; j++) {

        //filter on the formID
        if (this.bundle.forms[i].FORMID == this.bundle.elements[i].FORMID) {

          ///add the element label to the first column
          csv += this.bundle.elements[j].LABEL + ',';

          //loop through and add the element value to each column
          for (let n = 0; n < this.bundle.records.length; n++) {

            let val = removeNumberCommas(String(this.bundle.records[n][this.bundle.elements[j].ELEMENT]));

            csv += val

            //csv += String(val)
            //console.log(val)  

            ///continue to add columns if there are remaining records  .. elase add a new line
            if (n < this.bundle.records.length - 1) {
              csv += ','
            } else {
              csv = csv + '\n'
            }
          }

        }

      }

    }

    //console.log(csv)

    var a = document.createElement("a");
    document.body.appendChild(a);
    let blob = new Blob([csv], { type: "text/csv" }),

      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + ".csv";///'test.csv';//fileName;
    a.click();
    window.URL.revokeObjectURL(url);

  }

}

