<div #mainDiv_ll_model_functions_sp>


  <div id=vertTabHolder>
    <canvas #vertTab_ll_model_functions_sp></canvas>
  </div>

  <div *ngIf="bundle.showObj">

    <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_model_functions_sp [style.top.px]="bundle.spY" style="background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <div *ngFor="let group of bundle.groups " style="white-space: nowrap;">

        <div class="spCategory">{{group}}</div>

      <tr *ngFor="let item of bundle.records | visitCategory:{GROUP: group} ">


        <div class="statusCircle_holder" *ngIf="group != 'Models'" [style.width.px]="bundle.spW">

          <div class="spRowLabel_noIcon"
               [id]="bundle.prefix + item.FOLDERID"
               title={{item.FOLDERNAME}}
               [style.width.px]="bundle.spW" (click)="select($event)">
            {{item.FOLDERNAME}}
          </div>

        </div>

        <div class="statusCircle_holder" *ngIf="group == 'Models' " [style.width.px]="bundle.spW">

          <img class="editGear" *ngIf="bundle.canView " [id]="'GR' + item.FOLDERID" src="assets/icon_gear.png" alt="" (click)="edit($event)">

          <div class="spRowLabel"
               [id]="bundle.prefix + item.FOLDERID"
               title={{item.FOLDERNAME}}
               [style.width.px]="bundle.spW - 25" (click)="select($event)">
            {{item.FOLDERNAME}}
          </div>

        </div>

      </tr>
  </div>

  </jqxPanel>



  <!--BOTTOM-->
  <div id=bottomLine [style.top.px]="bundle.spB">
  </div>

  <!--SEARCH-->
  <div id=search *ngIf="bundle.canFilter"
       [style.top.px]="bundle.lblY - 0"
       [style.width.px]="bundle.spW"
       [style.left.px]="bundle.spW  - 10 "
       style="position: absolute;">


    <div class="wordBtnGroup">
      <div *ngIf="bundle.searchText  == '' ">
        <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
      </div>
      <div *ngIf="bundle.searchText  != '' ">
        <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
      </div>
    </div>

    <jqxPopover #popover_search
                [offset]='{ left: -85, top: 0 }'
                [arrowOffsetValue]="-0"
                [width]="220"
                [position]="'top'"
                [title]="'Filter Categories'"
                [selector]="'#search'">

      <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">
        <!--<img height = 20 style = "width: auto; "  src="assets/icon_magGlass.png">-->
        <div  (keyup)="onKeyUp($event)" style="float: left; ">
          <jqxTextArea #text_search
                       [width]="140"
                       [height]="25"
                       [placeHolder]="'Type Search'">

          </jqxTextArea>
        </div>

        <div style="float: left; padding-left:10px; padding-top:0px; ">
          <div style="clear:left">
            <div class="wordBtn" (click)="search()">Search</div>
          </div>
          <div style="clear:left">
            <div class="wordBtn" (click)="clear()">Clear</div>
          </div>
        </div>
      </div>



    </jqxPopover>
  </div>

  <!--showRemaining-->
  <div *ngIf="bundle.showTab && bundle.showRemaining">

    <!--ll-model-dash-->
    <div *ngIf="bundle.selectedId == 0"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
        <ll-model-dash></ll-model-dash>
    </div>

    <!--ll-info-request-visits-sp-->
    <div *ngIf="bundle.selectedId == 1"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
      <ll-info-request-visits-sp id="ll-model-functions-sp"></ll-info-request-visits-sp>
    </div>

    <!--ll-model-reports-sp-->
    <div *ngIf="bundle.selectedId == 2"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
      <ll-model-reports-sp></ll-model-reports-sp>
    </div>

    <!--ll-model-metrics-sp-->
    <div *ngIf="bundle.selectedId == 3"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
      <ll-model-metrics-sp></ll-model-metrics-sp>
    </div>

    <!--ll-model-displaydata-->
    <div *ngIf="bundle.selectedId == 4 ||bundle.selectedId == 5 || bundle.selectedId == 6"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
      <ll-model-displaydata></ll-model-displaydata>
    </div>

  </div>

  <!--ll-model-classes-sp-->
  <div *ngIf="bundle.selectedGROUP == 'classes'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; ">
    <ll-model-classes-sp id={{bundle.selectedFOLDERNAME}}></ll-model-classes-sp>
  </div>

  <!--ll-model-payers-sp-->
  <div *ngIf="bundle.selectedGROUP == 'payers'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; ">
    <ll-model-payers-sp id={{bundle.selectedFOLDERNAME}}></ll-model-payers-sp>
  </div>

  <!--ll-model-locations-sp-->
  <div *ngIf="bundle.selectedGROUP == 'locations'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; ">
    <ll-model-locations-sp id={{bundle.selectedFOLDERNAME}}></ll-model-locations-sp>
  </div>

</div>
