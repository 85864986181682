import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { LlRolesService } from '../ll-roles.service';

@Component({
  selector: 'll-contact-switch',
  templateUrl: './ll-contact-switch.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlContactSwitchComponent implements OnInit {

  @ViewChild('mainDiv_ll_contact_switch') mainDiv: ElementRef;
  @ViewChild('horzTabs_ll_contact_switch') public horzTabs: ElementRef;


  constructor(
    private LlScrollpaneService: LlScrollpaneService,
    private LlRolesService: LlRolesService,
    private LlPermissionsService: LlPermissionsService,
  ) { }

  bundle = {
    showHorzTabs: false,
    showRemaining: false,
    function: '',
    LlScrollpaneService: this.LlScrollpaneService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 20,
    remainingW: 0,
    remainingH: 0,
    showTab: true,
    horzTabsY: 40,
    hTabW: 120,
    hTabH: 25,
    hTabIndent: 50,
    hTabIndx: 0,
    horzTabs: [],
    horzTabs_02: [],
    canvasHT: {},
    ctxHT: {},
  }

  ngOnInit() { }

  ngOnDestroy() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.showHorzTabs = false;
    this.bundle.showRemaining = false;

    this.bundle.function = this.mainDiv.nativeElement.parentElement.id; 

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {
      this.bundle.remainingX = 0;
      this.bundle.remainingY = this.bundle.horzTabsY + this.bundle.hTabH + 3
      this.bundle.remainingW = this.bundle.parentW;;
      this.bundle.remainingH = this.bundle.parentH - this.bundle.remainingY;

      var canvasHT: HTMLCanvasElement = this.horzTabs.nativeElement;
      this.bundle.canvasHT = canvasHT
      this.bundle.ctxHT = canvasHT.getContext('2d');

      this.getData()
    });
  }

  getData() {

    let SYSTEMROLEID = this.LlRolesService.getActiveContactRoleId();

    this.bundle.horzTabs.splice(0);

    this.bundle.horzTabs.push({ lbl: "Info" });

    if (SYSTEMROLEID == 18 || this.LlPermissionsService.check("editUserRoles")) {
      this.bundle.horzTabs.push({ lbl: "User Roles" });
    }

    if (SYSTEMROLEID == 18 || this.LlPermissionsService.check("editRolePermissions")) {
      this.bundle.horzTabs.push({ lbl: "Permissions" });
    }
    
    //this.bundle.horzTabs.push({ lbl: "LogIns" });
    //this.bundle.horzTabs.push({ lbl: "Entry" });
    //this.bundle.horzTabs.push({ lbl: "AdmActs" });

    this.LlScrollpaneService.paintHorzTab(horzTabSelected, this.bundle.horzTabs, this.bundle.canvasHT, this.bundle.ctxHT, this.bundle);

    function horzTabSelected(indx) { }

    this.bundle.showHorzTabs = true;
    this.bundle.showRemaining = true;
    
  }


}
