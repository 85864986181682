<div #mainDiv_ll_model_graph_one>

  <div [style.left.px]="bundle.graphX"
       [style.top.px]="bundle.graphY"
       style="position: absolute;">

    <jqxChart #myChart_line
              [width]="bundle.graphW"
              [height]="bundle.graphH"
              [showLegend]="false"
              [showToolTips]="true"
              [enableAnimations]="true"
              [colorScheme]="'scheme04'"
              [enableCrosshairs]="true"
              [crosshairsColor]="'#111888'"
              [crosshairsLineWidth]="2"
              [showBorderLine]="false">
    </jqxChart>
  </div>

  <div [style.left.px]="bundle.graphW - 150"
       [style.top.px]="bundle.graphH - 15"
       [style.width.px]="150"
       [style.height.px]="15"
       style="position: absolute; background-color:white">
  </div>

  <div [style.left.px]="bundle.statsX"
       [style.top.px]="bundle.statsY"
       [style.width.px]="bundle.statsW"
       style="position: absolute;">

    <!--FITTED LINE-->
    <div style="clear:left; padding-top:0px;">

      <div style="float: left;">

        <jqxTooltip [position]="'left'"
                    [name]="'FITTED_LINE_TIP'"
                    [autoHideDelay]="30000"
                    [content]="bundle.FITTED_LINE_TIP"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

      </div>

      <div class="txtSmall" [style.width.px]="bundle.GRAPHDATA_INDENT" style="float: left; padding-left: 5px;">
        Red Line: Slope
      </div>

      <div [style.width.px]="10" style="float: left; padding-left: 5px; padding-Top: 5px; border-bottom-color: red; border-bottom-width: medium; ">

      </div>

    </div>

    <!--COUNT-->
    <div style="clear:left; padding-top:0px;">

      <div style="float: left;">

        <jqxTooltip [position]="'left'"
                    [name]="'COUNT_TIP'"
                    [autoHideDelay]="30000"
                    [content]="bundle.COUNT_TIP"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        Day Count:
      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        {{bundle.statPackage.COUNT}}
      </div>

    </div>

    <!--AVERAGE-->
    <div style="clear:left; padding-top:0px;">

      <div style="float: left;">

        <jqxTooltip [position]="'left'"
                    [name]="'MEAN_TIP'"
                    [autoHideDelay]="30000"
                    [content]="bundle.MEAN_TIP"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        Average:
      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        {{bundle.statPackage.MEAN_formated}}
      </div>

    </div>

    <!--SLOPE-->
    <div style="clear:left; padding-top:0px;">

      <div style="float: left;">

        <jqxTooltip [position]="'left'"
                    [name]="'SLOPE_TIP'"
                    [autoHideDelay]="30000"
                    [content]="bundle.SLOPE_TIP"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        Slope Daily:
      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        {{bundle.statPackage.SLOPE_formated}}
      </div>

    </div>

    <!--SLOPEPCT-->
    <div style="clear:left; padding-top:0px;">

      <div style="float: left;">

        <jqxTooltip [position]="'left'"
                    [name]="'SLOPEPCT_TIP'"
                    [autoHideDelay]="30000"
                    [content]="bundle.SLOPEPCT_TIP"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        Slope Percent:
      </div>

      <div class="txtSmall" style="float: left; padding-left: 5px;">
        {{bundle.statPackage.SLOPEPCT}}
      </div>

    </div>

  </div>

</div>

