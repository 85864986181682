<div #mainDiv_ll_company_info>

  <div [hidden]="!bundle.showObj"
       [style.top.px]="bundle.headerY"
       [style.left.px]="0"
       [style.width.px]="bundle.objW"
       [style.height.px]="bundle.objH"
       style="position: absolute;">

    <jqxPanel style="white-space: nowrap; background-color: transparent; border: none;  "
              [autoUpdate]="true"
              [width]="bundle.objW"
              [height]="bundle.objH">

      <div style="padding-left:15px; padding-top:10px; padding-bottom:20px;">

        <!-- {{bundle.topMsg01 }}-->
        <!--COMPANYID-->
        <div *ngIf="bundle.COMPANYID != 0" style="clear: left; padding-top:10px; ">

          <div style="float: left; padding-left:10px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left;">
              Application Id:
            </div>

            <div style="float: left; padding-left:10px;">
              {{this.bundle.company.COMPANYID}}
            </div>
          </div>
        </div>

        <!--Organization Status:-->
        <div style="clear: left; padding-top: 10px; ">

          <div style="float: left; padding-left:10px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W + 0" style="float: left; padding-left:0px">
              Organization Status:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxDropDownList #myDropDownList style="float: left;"
                               [theme]="'material-green'"
                               [width]="100"
                               [height]="25"
                               [source]="bundle.companyStatusActiveInactiveChoices"
                               [dropDownHorizontalAlignment]="'left'"
                               [dropDownVerticalAlignment]="'bottom'"
                               [dropDownWidth]="100"
                               [disabled]='false'
                               (onSelect)="selectSTUDYCOMPANYSTATUS($event)"
                               [selectedIndex]="bundle.statusIndex">

              </jqxDropDownList>
            </div>
          </div>
        </div>

        <!--text_companyName-->
        <div style="clear: left; padding-top:0px; ">

          <div style="float: left; ">
            <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
          </div>

          <div style="float: left; padding-left:1px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left;">
              Name:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxTextArea #text_companyName
                           [width]="bundle.col02W"
                           [height]="25"
                           [(ngModel)]="bundle.company.COMPANYNAME"
                           [placeHolder]="'Type organization name here'"
                           (keydown)="onKeyDown($event)">
              </jqxTextArea>
            </div>
          </div>
        </div>

        <!--text_companyAbrev:-->
        <div style="clear: left; padding-top:0px; ">

          <div style="float: left; ">
            <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
          </div>

          <div style="float: left; padding-left:1px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left; ">
              Abreviation (15 Chars):
            </div>

            <div (keyup)="abrevCharLimit($event)" style="float: left; padding-left:10px;">
              <jqxTextArea #text_companyAbrev
                           [width]="bundle.col02W"
                           [height]="25"
                           [(ngModel)]="bundle.company.COMPANYABREV"
                           [maxLength]="15"
                           [placeHolder]="'Type organization abbreviation here (15 characters)'"
                           (keydown)="onKeyDown($event)">
              </jqxTextArea>
            </div>
          </div>
        </div>

        <!--text_companyEmail-->
        <div style="clear: left; padding-top:0px; ">

          <div style="float: left; ">
            <!--<img height = 8 style = "width: auto;" src="assets/icon_asterisk.png" >-->
          </div>

          <div style="float: left; padding-left:10px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left; ">
              Company Email:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxTextArea #text_companyEmail
                           [width]="bundle.col02W"
                           [height]="25"
                           [(ngModel)]="bundle.company.COMPANYEMAILADDRESS"
                           [placeHolder]="'Type company Email  here'"
                           (keydown)="onKeyDown($event)">
              </jqxTextArea>
            </div>
          </div>
        </div>

        <!--text_companyCity-->
        <div style="clear: left; padding-top:0px; ">

          <div style="float: left; padding-left:10px;  padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left; ">
              City:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxTextArea #text_companyCity
                           [width]="bundle.col02W"
                           [height]="25"
                           [(ngModel)]="bundle.company.COMPANYCITY"
                           [placeHolder]="'Type city here'"
                           (keydown)="onKeyDown($event)">
              </jqxTextArea>
            </div>
          </div>
        </div>

        <!--State-Prov:-->
        <div style="clear: left; padding-top:0px; ">

          <div style="float: left; padding-left:10px;  padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left; ">
              State-Prov:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxDropDownList #myDropDownList style="float: left;"
                               [theme]="'material-green'"
                               [width]="bundle.col02W"
                               [height]="25"
                               [source]="bundle.states"
                               [dropDownHorizontalAlignment]="'left'"
                               [dropDownVerticalAlignment]="'bottom'"
                               [dropDownWidth]="400"
                               [disabled]='false'
                               (onSelect)="selectState($event)"
                               [selectedIndex]="bundle.stateIndex">

              </jqxDropDownList>
            </div>
          </div>
        </div>

        <!--Country:-->
        <div style="clear: left; padding-top:0px; ">

          <div style="float: left; padding-left:10px;  padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left; ">
              Country:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxDropDownList #myDropDownList style="float: left;"
                               [theme]="'material-green'"
                               [width]="bundle.col02W"
                               [height]="25"
                               [source]="bundle.countries"
                               [dropDownHorizontalAlignment]="'left'"
                               [dropDownVerticalAlignment]="'bottom'"
                               [dropDownWidth]="400"
                               [disabled]='false'
                               (onSelect)="selectCountry($event)"
                               [selectedIndex]="bundle.countryIndex">

              </jqxDropDownList>
            </div>
          </div>
        </div>

        <!--Organization Type  CompanyID = Zero means adding company-->
        <div style="clear: left; padding-top:0px; ">

          <div style="float: left; ">
            <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
          </div>

          <div style="float: left; padding-left:1px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W + 0" style="float: left; padding-left:0px">
              Organization Type:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxDropDownList #dropDown_companyType style="float: left;"
                               [theme]="'material-green'"
                               [width]="bundle.col02W"
                               [height]="25"
                               [source]="bundle.companyTypeChoices"
                               [dropDownHorizontalAlignment]="'left'"
                               [dropDownVerticalAlignment]="'bottom'"
                               [dropDownWidth]="400"
                               [disabled]='false'
                               [selectedIndex]="bundle.companyTypeSelectIndex"
                               (onChange)="selectType($event)">

              </jqxDropDownList>
            </div>
          </div>
        </div>

        <!--Specialty-->
        <div style="clear: left; padding-top:0px; ">


          <div style="float: left; padding-left:10px; padding-top:5px;">

            <div class="txtNorm" [style.width.px]="bundle.col01W + 0" style="float: left; padding-left:0px">
              Organization Specialty:
            </div>

            <div style="float: left; padding-left:10px;">
              <jqxDropDownList #dropDown_companyType style="float: left;"
                               [theme]="'material-green'"
                               [width]="bundle.col02W"
                               [height]="25"
                               [source]="bundle.companyTaxonomyChoices"
                               [dropDownHorizontalAlignment]="'left'"
                               [dropDownVerticalAlignment]="'bottom'"
                               [dropDownWidth]="400"
                               [disabled]='false'
                               [selectedIndex]="bundle.companyTaxonomyIndex"
                               (onSelect)="selectTaxonomy($event)">

              </jqxDropDownList>
            </div>
          </div>
        </div>

        <!--Variable Fields-->
        <div *ngIf="bundle.SUBJECTVISITFORMID != 0">
          <div class="txtNorm" [style.width.px]="bundle.col01W + 0" style="clear: left; padding-left: 10px; padding-top:10px; ">
            Variable Fields:
          </div>

          <div style="clear: left; padding-left:10px; padding-top:5px;">

            <div *ngFor="let item of bundle.questions; index as i" style="padding-top: 4px; ">

              <div *ngIf="item.NGWIDGET == 'number' || item.NGWIDGET == 'ftx'" [style.width.px]="bundle.col01W + bundle.col02W">
                <ll-info-request-widget-general id={{item.STUDYQUESTIONID}}></ll-info-request-widget-general>
              </div>

              <div style="clear:left;" *ngIf="item.NGWIDGET == 'ftx_long'" [style.width.px]="bundle.col01W + bundle.col02W">
                <ll-info-request-widget-long-text id={{item.STUDYQUESTIONID}}></ll-info-request-widget-long-text>
              </div>

              <div style="clear:left;" *ngIf="item.NGWIDGET == 'docs'" [style.width.px]="bundle.col01W + bundle.col02W">
                <ll-info-request-widget-docs id={{item.STUDYQUESTIONID}}></ll-info-request-widget-docs>
              </div>

            </div>



          </div>
        </div>





        <!--Save Changes-->
        <div [style.width.px]="bundle.objW - 40">

          <div style="float:left; padding-right:20px; ">
            <div class="wordBtnGroup_popover">
              <div class="wordBtn14" (click)=" editCompany()">Select drop down, hit enter in text field, or click here to Save Changes</div>
            </div>



          </div>


        </div>

        <!--Required-->
        <div *ngIf ="bundle.showRqrMsg" style="clear: left; padding-right: 20px; color: red;">

          <div [style.width.px]="bundle.objW - 40" style="float: left; padding-left: 0px; color: red;">
            Are you certain you want to change organization type?
          </div>

          <div [style.width.px]="bundle.objW - 40" style="float: left; padding-left: 0px; color: red;">
            All company data will be removed and all information request/reports will be removed if the new company type requires different forms.  
          </div>

          <div [style.width.px]="bundle.objW - 40" style="float: left; padding-left: 0px; color: red;">
            If still yes, select the “Save Changes” word button above.
          </div>

        </div>





      </div>

    </jqxPanel>



  </div>

</div>


