<div #mainDiv_ll_contact_info>

  <div [hidden]="!bundle.showDisplay">

    <div [style.top.px]="0"
         [style.left.px]="0"
         style="position: absolute;">

      <jqxPanel style="white-space: nowrap; background-color: transparent; border: none;  border-bottom: 2px solid  rgb(160, 158, 158);"
                [autoUpdate]="true"
                [width]="bundle.panelW"
                [height]="bundle.panelH">

        <div style="padding-left:15px; padding-top:10px; padding-bottom:0px;">

          {{bundle.topMsg01 }}

          <!--Contact ID-->
          <div style="clear: left; padding-top:10px; ">
                      

            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="bundle.textEntryX" style="float: left;">
                User ID:  
              </div>

              <div style="float: left; padding-left:15px;">
                {{bundle.contactId }}
              </div>

            </div>
          </div>


          <!--Email-->
          <div style="clear: left; padding-top:10px; ">

            <div style="float: left; ">
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="bundle.textEntryX" style="float: left;">
                Email:
              </div>

              <div style="float: left; padding-left:10px;">
                <jqxTextArea #text_CONTACTEMAIL
                             [width]="bundle.textEntryW"
                             [height]="25"
                             [placeHolder]="'Type email address'">
                </jqxTextArea>
              </div>
            </div>
          </div>

          <!--First Name-->
          <div style="clear: left; padding-top:0px; ">

            <div style="float: left; ">
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="bundle.textEntryX" style="float: left;">
                First Name:
              </div>

              <div style="float: left; padding-left:10px;">
                <jqxTextArea #text_CONTACTFIRST
                             [width]="bundle.textEntryW"
                             [height]="25"
                             [placeHolder]="'Type first name here'">
                </jqxTextArea>
              </div>
            </div>
          </div>

          <!--Last Name-->
          <div style="clear: left; padding-top:0px; ">

            <div style="float: left; ">
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="bundle.textEntryX" style="float: left;">
                Last Name:
              </div>

              <div style="float: left; padding-left:10px;">
                <jqxTextArea #text_CONTACTLAST
                             [width]="bundle.textEntryW"
                             [height]="25"
                             [placeHolder]="'Type last name here'">
                </jqxTextArea>
              </div>
            </div>
          </div>

          <!--Company-->
          <div *ngIf="bundle.companyIndex != 9999999999" style="clear: left; padding-top:0px; ">

            <div style="float: left; ">
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="bundle.textEntryX" style="float: left;">
                Company:
              </div>

              <div style="float: left; padding-left:10px;">
                <jqxDropDownList #dropDownList_company style="float: left;"
                                 [theme]="'material-green'"
                                 [width]="bundle.textEntryW"
                                 [height]="25"
                                 [source]="bundle.companyChoices"
                                 [dropDownHorizontalAlignment]="'left'"
                                 [dropDownVerticalAlignment]="'bottom'"
                                 [dropDownWidth]="400"
                                 [disabled]='false'
                                 (onSelect)="selectCompany($event)"
                                 [selectedIndex]="bundle.companyIndex">

                </jqxDropDownList>
              </div>
            </div>
          </div>

          <!--Invited / Reset Password-->
          <div style="clear: left; padding-top:10px; ">

            <div class="txtNorm" style="float: left; width: 75px; padding-left: 10px;">
              Invited:
            </div>

            <div class="txtNorm" style="float: left; width:50px; padding-top:0px; padding-left:20px;">
              {{bundle.SENTINVITEYESNO}}
            </div>

            <div id=resetPassword style="float:left; padding-left:20px; padding-top:2px; ">

              <div class="wordBtnGroup">
                <div class="wordBtn" (click)="noAction()">Reset Password</div>
              </div>

              <jqxTooltip style="float: left;"
                          [position]="'right'"
                          [name]="'movieTooltip'"
                          [autoHideDelay]="30000"
                          [content]="'Reset this users password.  They will be sent a temp password to login and required a reset'"
                          [opacity]="1.0"
                          [width]="200">

                <img class="infoBtn_12" src="assets/infoBtn.png" />

              </jqxTooltip>


              <jqxPopover #popover_resetPassword
                          [offset]='{ left: -150, top: 0 }'
                          [arrowOffsetValue]="-150"
                          [width]="400"
                          [position]="'top'"
                          [title]="'Reset Password'"
                          [showCloseButton]="true"
                          [selector]="'#resetPassword'">

                <div style="display: table;">
                  <img height=50 style="width: auto; float: left; padding-right:20px;" src="assets/icon_hermes.png">
                  <div class="txtNorm" width=50>Are you certian you want to reset this users password?</div>
                </div>

                <div class="wordBtnGroup_popover">
                  <div class="wordBtn" (click)="resetAccount()">Yes, Reset Password</div>
                </div>

              </jqxPopover>
            </div>
          </div>

          <!--Message-->
          <div style="clear: left; padding-top: 10px; padding-left: 20px; color: red;">
            {{bundle.editUserMsg}}
          </div>

          <!--Save Changes-->
          <div [style.padding-left.px]="bundle.textEntryW" style="clear: left; padding-top: 5px; ">
            <div class="wordBtn" (click)="editContact()">Save Changes</div>
          </div>


        </div>

      </jqxPanel>



    </div>



  </div>





</div>
