import { ReturnStatement } from '@angular/compiler';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { LlRolesService } from './ll-roles.service';
import { LlPermissionsService } from './ll-permissions.service';
import { LlCompaniesService } from './ll-companies.service';
import { javaDate_to_YYYYMMDD } from "./utility-date";

@Directive()
@Injectable({
  providedIn: 'root'
})

export class LlModelsService {

  @Output() refreshPayerClass: EventEmitter<string> = new EventEmitter();
  @Output() ll_model_functions_sp_GROUP_newFOLDERNAME: EventEmitter<string> = new EventEmitter();
  @Output() ll_company_models_sp_mode: EventEmitter<string> = new EventEmitter();
  @Output() ll_admin_generic_dg_reflectChange: EventEmitter<any> = new EventEmitter();
  //@Output() ll_model_locations_sp_reflect: EventEmitter<any> = new EventEmitter();
  //@Output() ll_model_sp_reflectChange: EventEmitter<any> = new EventEmitter();

  constructor(public ServerService: ServerService,
    private UserService: UserService,
    private LlRolesService: LlRolesService,
    private LlPermissionsService: LlPermissionsService,
    private LlCompaniesService: LlCompaniesService,
  ) { }

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    SYSTEMROLEID: 0,
    CONTACTROLEID: 0,
    STUDYID_SYS: 0,
    CONTACTID: 0,
    COMPANYID: 0,
    COMPANYID_ACTIVE: 0,
    CLIENTID:0,
    REPORTOWNERCLIENTID: 0,
    LOANMODELID: 0,
    LOCATIONID: 0,
    PAYERID: 0,
    REPORTFORMCONTEXTID: 0,
    REPORTFORMCONTEXTGROUP:'',
    ACTION:'',
    NEWNAME: '',
    CLIENTFINANCIALCLASSID: 0,
    daysBack:0,
    ELEMENT: '',
    ELEMENTID: 0,
    CLIENTCOMPANYID: 0,
    SYSTEMOPERATIONREPORTSID:0,
    date_calc: '',
    date_now: '',
    THROUGHDATE_NUM: '',
    PAYER:[],
    MODEL: [],
    MODELNAME: '',
    SEARCHPARAMS: [],
    PROVIDER_SYSID: '',
    STATUS:'A',
  }

  bundle_local = {
    ServerService: this.ServerService,
    LlPermissionsService: this.LlPermissionsService,
    vars: this.vars,
    refreshPayerClass: this.refreshPayerClass,
    ll_admin_generic_dg_reflectChange: this.ll_admin_generic_dg_reflectChange,
    exportFileName:'',
    modelRecords: [],
    inBundle: [],
    models: [],
    payerClasses: [],
    reports: [],
    forms: [],
    elements:[],
    metricGroups: [],
    locations: [],
    payers:[],
  }

  NG_LL_user_models_get(callback01: Function, inBundle) {


    let dummyToTriggerCode = this.getThroughDate_num();

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_user_models_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    this.bundle_local.vars.STATUS = inBundle.STATUS;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      bundle_local.modelRecords.splice(0);

      if (contactRole.SYSTEMROLEID == 18
        || bundle_local.LlPermissionsService.check("addEditCompany")
        || bundle_local.LlPermissionsService.check("editUserRoles")
        || bundle_local.LlPermissionsService.check("addEditUser")
        || bundle_local.LlPermissionsService.check("editRolePermissions")
        || bundle_local.LlPermissionsService.check("viewRolePermissions")
        || bundle_local.LlPermissionsService.check("viewLogInRecords")
        || bundle_local.LlPermissionsService.check("adminEventTrail")
        || bundle_local.LlPermissionsService.check("viewEmailRoutines")
        || bundle_local.LlPermissionsService.check("editEmailRoutines")
        || bundle_local.LlPermissionsService.check("viewEmailRecords")
      ) {
        bundle_local.modelRecords.push({ GROUP: "Other", FOLDERNAME: "Admin", FOLDERID: -999, COMPANYID:0 });
      }

      if (contactRole.SYSTEMROLEID == 18
        || bundle_local.LlPermissionsService.check("viewNewsLetters")
      ) {
        bundle_local.modelRecords.push({ GROUP: "Other", FOLDERNAME: "Newsletters", FOLDERID: -888, COMPANYID: 0  });
      }

      if (arr.length > 0) {
        bundle_local.vars.LOANMODELID = arr[0].LOANMODELID;
      }

      ///add the server retrived array-modelRecords to the modelRecords
      for (let i = 0; i < arr.length; i++) {
        arr[i].GROUP = "Models";

        bundle_local.modelRecords[bundle_local.modelRecords.length] = arr[i];
      }

      callback01(bundle_local.modelRecords, inBundle);

    }
  }

  setActiveId(LOANMODELID) {
    this.bundle_local.vars.LOANMODELID = LOANMODELID;
  }

  getActiveId() {
    return this.bundle_local.vars.LOANMODELID
  }

  getActiveCategory(callback01?, bundle?) {

    //console.log("getActiveCategory: "+this.activeFOLDERID)

    for (let i = 0; i < this.bundle_local.modelRecords.length; i++) {
      if (this.bundle_local.modelRecords[i].FOLDERID == this.bundle_local.vars.LOANMODELID) {

        if (callback01 != null) {
          callback01(this.bundle_local.modelRecords[i], bundle)
        } else {
          return this.bundle_local.modelRecords[i];
        }
      }
    }

  }

  NG_LL_company_models_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();
    //var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_company_models_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId()
    

    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      if (arr.length > 0 && bundle_local.vars.LOANMODELID < 1) {
        bundle_local.vars.LOANMODELID = arr[0].LOANMODELID;
      }

      bundle_local.models = arr;

      callback01(bundle_local.models, inBundle)

    }
  }

  NG_LL_company_model_add( MODELNAME, callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_company_model_add";
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();
    this.bundle_local.vars.MODELNAME = MODELNAME;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)

      if (arr.length > 0) {
        bundle_local.vars.LOANMODELID = arr[0].LOANMODELID_NEW;
      }

      bundle_local.models = arr;

      callback01(inBundle)

    }
  }

  getExistingCompanyModels(callback01: Function, inBundle) {
    callback01(this.bundle_local.models, inBundle)
  }

  getActiveLoanModel(callback01?: Function, inBundle?) {

    for (var i = 0; i < this.bundle_local.models.length; i++) {
      if (this.bundle_local.models[i].LOANMODELID == this.bundle_local.vars.LOANMODELID) {
                
        if (callback01 == null) {
          return this.bundle_local.models[i]
        } else {
          callback01(this.bundle_local.models[i], inBundle)
        }
      }
    }

    

  }

  setThroughDate_num(newThroughDate_num) {
    this.bundle_local.vars.THROUGHDATE_NUM = newThroughDate_num;
  }

  getThroughDate_num() {

    if (this.bundle_local.vars.THROUGHDATE_NUM == "") {
      var n = new Date();

      this.setThroughDate_num(javaDate_to_YYYYMMDD(n))
    }

    return this.bundle_local.vars.THROUGHDATE_NUM;
  }

  getThroughDate() {
    return this.bundle_local.vars.THROUGHDATE_NUM;
  }

  setDaysBack(daysBack) {
    this.bundle_local.vars.daysBack = daysBack;
  }

  NG_LL_company_model_get(callback01: Function, inBundle ) {

    this.bundle_local.vars.ac = "NG_LL_company_model_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)
      callback01(arr, inBundle)

    }
  }

  NG_LL_company_model_edit(MODEL, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_company_model_edit";
    this.bundle_local.vars.MODEL = MODEL;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {


    }

  }

  NG_LL_model_AR_payerClasses_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      bundle_local.payerClasses = arr;

      if (arr.length > 0) {
        bundle_local.vars.CLIENTFINANCIALCLASSID = arr[0].CLIENTFINANCIALCLASSID
      }

      callback01(arr, inBundle)
      
    }
  }

  getExistingPayerClasses(callback01: Function, inBundle) {
    callback01(this.bundle_local.payerClasses, inBundle);
  }

  setActivePayerClass(CLIENTFINANCIALCLASSID) {
    this.bundle_local.vars.CLIENTFINANCIALCLASSID = CLIENTFINANCIALCLASSID;
    this.bundle_local.vars.PAYERID = 0;
    this.bundle_local.vars.LOCATIONID = 0
  }

  getActivePayerClassId() {
    return this.bundle_local.vars.CLIENTFINANCIALCLASSID;
  }

  NG_LL_model_AR_payerClasses_addEdit(ACTION, NEWNAME, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_addEdit";
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.NEWNAME = NEWNAME;    

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      if (ACTION == 'ADD') {        
        bundle_local.vars.CLIENTFINANCIALCLASSID = arr[0].NEW_CLIENTFINANCIALCLASSID
      }

      bundle_local.payerClasses = arr;
      bundle_local.refreshPayerClass.emit();
    }
  }

  NG_LL_model_AR_payerClasses_picklist_get(callback01, inBundle, obj) {
      
    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_picklist_get";    

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)    

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
        } else {
          arr[i].SELECTED = false;
        }
      }
            
      callback01(arr, inBundle, obj)
     
    }


  }

  NG_LL_model_AR_payerClasses_picklist_edit(ACTION, PAYERID) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_picklist_edit";    
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.PAYERID = PAYERID;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }

  }

  NG_LL_dashboard_get(callback01?, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_dashboard_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      bundle_local.dashboardElements = arr;

      bundle_local.activeDashboardELEMENT = arr[0].PREFERENCEELEMENT;

      callback01(arr, inBundle);
    }
  }

  NG_LL_model_AR_reports_list_get(callback01: Function, ACTION, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_list_get";
    this.bundle_local.vars.ACTION = ACTION;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      bundle_local.reports = arr;

      if (arr.length > 0) {
        bundle_local.vars.THROUGHDATE_NUM = arr[0].THROUGHDATE_NUM     
        bundle_local.vars.SYSTEMOPERATIONREPORTSID = arr[0].SYSTEMOPERATIONREPORTSID;
      } else {
        bundle_local.vars.SYSTEMOPERATIONREPORTSID = 0;
      }

      if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }
    }
  }

  setActiveSYSTEMOPERATIONREPORTSID(SYSTEMOPERATIONREPORTSID) {
    this.bundle_local.vars.SYSTEMOPERATIONREPORTSID = SYSTEMOPERATIONREPORTSID;
  }

  getActiveSYSTEMOPERATIONREPORTSID() {
    return this.bundle_local.vars.SYSTEMOPERATIONREPORTSID;
  }

  getActiveReport(callback01?: Function, inBundle?) {

    for (var i = 0; i < this.bundle_local.reports.length; i++) {
      if (this.bundle_local.reports[i].SYSTEMOPERATIONREPORTSID == this.bundle_local.vars.SYSTEMOPERATIONREPORTSID) {

        if (callback01 == null) {
          return this.bundle_local.reports[i];
        } else {
          callback01(this.bundle_local.reports[i], inBundle)
        }

      }

    }

  }

  NG_LL_model_AR_reports_grid_elements_get(callback01: Function, inBundle, obj?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_grid_elements_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      callback01(arr, inBundle, obj)
    }

  }

  getReportData(callback01?: Function, inBundle?, obj?) {

    var report = this.getActiveReport();

    this.bundle_local.vars.ac = report.OPSREPORTCOMPONENT;    

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      if (callback01 != null && obj != null) {
        callback01(arr, inBundle, obj)
      } else if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }
    }
  }

  NG_LL_model_AR_displayData_graph_data_get(callback01?: Function, inBundle?, obj?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_graph_data_get";

    if (this.bundle_local.vars.LOCATIONID != 0) {
      this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_graph_data_new_get";
      this.bundle_local.vars.ACTION = 'Location'
    }

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

     ///console.log(arr) 

      callback01(arr, inBundle, obj);

    }
  }

  setActiveREPORTFORMCONTEXTID(REPORTFORMCONTEXTID, REPORTFORMCONTEXTGROUP) {

    this.bundle_local.vars.REPORTFORMCONTEXTID = REPORTFORMCONTEXTID
    this.bundle_local.vars.REPORTFORMCONTEXTGROUP = REPORTFORMCONTEXTGROUP

    //console.log("REPORTFORMCONTEXTID: " + this.bundle_local.vars.REPORTFORMCONTEXTID)
    //console.log("REPORTFORMCONTEXTGROUP: " + this.bundle_local.vars.REPORTFORMCONTEXTGROUP)

    if (this.bundle_local.vars.REPORTFORMCONTEXTID != -99) {
      var n = new Date();

      this.setThroughDate_num(javaDate_to_YYYYMMDD(n));
    }

    ////set the active ELEMENT to null so the new default will be the top element in the view. see: NG_LL_model_AR_formElements_get
    this.bundle_local.vars.ELEMENT = "";

    //set active SYSTEMOPERATIONREPORTSID  to zero of server-side NG_LL_model_AR_formElements_get toggle code.
    this.bundle_local.vars.SYSTEMOPERATIONREPORTSID = 0;

    ///does not equal metrics (55)
    if (REPORTFORMCONTEXTGROUP.toLowerCase() == 'classes' && REPORTFORMCONTEXTID != 55) {
      this.bundle_local.vars.PAYERID = 0;
      this.bundle_local.vars.LOCATIONID = 0

    } else if (REPORTFORMCONTEXTGROUP.toLowerCase() == 'payers' && REPORTFORMCONTEXTID != 55) {

      this.bundle_local.vars.CLIENTFINANCIALCLASSID = 0;
      this.bundle_local.vars.LOCATIONID = 0

    } else if (REPORTFORMCONTEXTGROUP.toLowerCase() == 'locations' && REPORTFORMCONTEXTID != 55) {

      this.bundle_local.vars.CLIENTFINANCIALCLASSID = 0;
      this.bundle_local.vars.PAYERID = 0;

    } else {

      this.bundle_local.vars.PAYERID = 0;
      this.bundle_local.vars.LOCATIONID = 0
      this.bundle_local.vars.CLIENTFINANCIALCLASSID = 0;
    }

    //console.log("PAYERID: " + this.bundle_local.vars.PAYERID)
    //console.log("LOCATIONID: " + this.bundle_local.vars.LOCATIONID)
    //console.log("CLIENTFINANCIALCLASSID: " + this.bundle_local.vars.CLIENTFINANCIALCLASSID)
  }

  getActiveREPORTFORMCONTEXTID() {
    return this.bundle_local.vars.REPORTFORMCONTEXTID;
  }

  selectGROUP_newFOLDERNAME(selectedFOLDERNAME) {
    this.ll_model_functions_sp_GROUP_newFOLDERNAME.emit(selectedFOLDERNAME);
  }

  NG_LL_model_AR_locations_get(callback01: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_locations_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.locations = arr;

      if (arr.length > 0) {
        bundle_local.vars.LOCATIONID = arr[0].LOCATIONID
      }

      if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }

    }
  }

  setActiveLOCATIONID(LOCATIONID) {
    this.bundle_local.vars.LOCATIONID = LOCATIONID;
    this.bundle_local.vars.CLIENTFINANCIALCLASSID = 0;
    this.bundle_local.vars.PAYERID = 0;
  }

  getActiveLOCATIONID() {
    return this.bundle_local.vars.LOCATIONID;
  }

  NG_LL_model_AR_location_target_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_location_target_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      // console.log(arr)

      callback01(arr, inBundle)

    }
  }

  location_CMS_search_mode(LOCATIONID) {
    this.setActiveLOCATIONID(LOCATIONID)
    this.ll_company_models_sp_mode.emit('findCMS')
  }

  NG_LL_model_AR_location_CMS_search(callback01: Function, searchParams, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_location_CMS_search";

    this.bundle_local.vars.SEARCHPARAMS = searchParams;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      callback01(arr, inBundle, obj);
    }
  }

  NG_LL_model_AR_location_CMS_edit(LOCATION_CMS,inBundle) {

    this.bundle_local.inBundle = inBundle;

    this.bundle_local.vars.ac = "NG_LL_model_AR_location_CMS_edit";
    this.bundle_local.vars.PROVIDER_SYSID = LOCATION_CMS[0].PROVIDER_SYSID;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      LOCATION_CMS[0].TRIGGER = 'location_cms'
      LOCATION_CMS[0].LOCATIONID = bundle_local.vars.LOCATIONID;

      bundle_local.ll_admin_generic_dg_reflectChange.emit(LOCATION_CMS);

      
    }

  }

  NG_LL_model_AR_locations_picklist_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_locations_picklist_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)    

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)

    }
  }

  NG_LL_model_AR_locations_picklist_edit(ACTION, LOCATIONID) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_locations_picklist_edit";
    this.bundle_local.vars.LOCATIONID = LOCATIONID;
    this.bundle_local.vars.ACTION = ACTION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }

  }

  NG_LL_model_AR_payers_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)
      bundle_local.payers = arr


      if (arr.length > 0) {
        bundle_local.vars.PAYERID = arr[0].PAYERID
      }

      callback01(bundle_local.payers, inBundle)
     
    }
  }

  setActivePAYERID(PAYERID) {
    this.bundle_local.vars.PAYERID = PAYERID;
    this.bundle_local.vars.CLIENTFINANCIALCLASSID = 0;
    this.bundle_local.vars.LOCATIONID = 0;

    //console.log(PAYERID)
  }

  getActivePAYERID() {
    return this.bundle_local.vars.PAYERID;
  }

  payer_edit_mode(PAYERID) {
    this.setActivePAYERID(PAYERID)
    this.ll_company_models_sp_mode.emit('editPayer')
  }

  NG_LL_model_AR_payers_target_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_target_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      callback01(arr, inBundle)

    }
  }

  NG_LL_model_AR_payers_target_edit(callback01: Function, PAYER, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_target_edit";
    this.bundle_local.vars.PAYER = PAYER;
    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      PAYER[0].TRIGGER = 'payer'

      bundle_local.ll_admin_generic_dg_reflectChange.emit(PAYER);

    }
  }

  NG_LL_model_AR_payers_picklist_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_picklist_get"

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr) 

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)

    }
  }

  NG_LL_model_AR_payers_picklist_edit(ACTION, PAYERID) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_picklist_edit";
    this.bundle_local.vars.PAYERID = PAYERID;
    this.bundle_local.vars.ACTION = ACTION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }

  }

  NG_LL_model_AR_reports_BBC_locations_get(callback01: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_BBC_locations_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.locations = arr;

      if (arr.length > 0) {
        bundle_local.vars.LOCATIONID = arr[0].LOCATIONID
      }

      if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }

    }
  }

  NG_LL_model_AR_reports_BBC_PDF_one(inRecords, inBundle) {
        
    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_BBC_PDF_one";
    
    this.bundle_local.vars.PAYERID = 0;
    this.bundle_local.vars.LOCATIONID = 0;
    this.bundle_local.vars.CLIENTFINANCIALCLASSID = 0;
    this.bundle_local.vars.date_calc = inBundle.date_calc;
    this.bundle_local.vars.date_now = inBundle.date_now;

    const keys = Object.keys(inRecords);

    for (const key of keys) {
      this.bundle_local.vars[key.toUpperCase()] = inRecords[key.toUpperCase()];
    }

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)

      var n = new Date()
      let nowDate = javaDate_to_YYYYMMDD(n);

      let name = nowDate + "_BBC_" + bundle_local.vars.COMPANYNAME + "_" + bundle_local.vars.MODELNAME + ".pdf"

      console.log(name)


      let b64Data = arr[0].UPLOADCONTENT;
      let mimeType = "application/pdf"
      let blob = base64ToBlob02(b64Data, mimeType, 512)

      //if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
      //if (window.navigator ) { // IE workaround
        ///window.navigator.msSaveOrOpenBlob(blob, name);

      //} else { //not IE

        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = name;
        a.click();
        URL.revokeObjectURL(objectUrl);

      //}

      function base64ToBlob02(b64Data, contentType, sliceSize) {
        b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);

          let byteNumbers = new Array(slice.length);

          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          let byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      }


    }
  }

  NG_LL_model_AR_displayData_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_get";

    if (this.bundle_local.vars.LOCATIONID != 0) {
      //this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_new_get";
      //this.bundle_local.vars.ACTION = 'Location'
    }

    //console.log("PAYERID: " + this.bundle_local.vars.PAYERID)
    //console.log("LOCATIONID: " + this.bundle_local.vars.LOCATIONID)
    //console.log("CLIENTFINANCIALCLASSID: " + this.bundle_local.vars.CLIENTFINANCIALCLASSID)

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

     /// console.log(arr) 

      if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }
    }
  }

  NG_LL_model_AR_formElements_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_formElements_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.elements.splice(0);
      bundle_local.forms.splice(0);
      bundle_local.elements = arr;
      
      var foundForm: any = [];
      let indx = 0;

      for (let i = 0; i < arr.length; i++) {

        arr[i].ELEMENT = arr[i].ELEMENT.toUpperCase();
       
        if (foundForm.indexOf(arr[i].FORMNAME) == -1) {
          foundForm.push(arr[i].FORMNAME);

          bundle_local.forms.push({ FORMNAME: arr[i].FORMNAME, FORMID: arr[i].FORMID, rowY: indx * 20 });

          indx++
        }

        arr[i].rowY = indx * 20

        indx++
      }

      if (arr.length > 0 && bundle_local.vars.ELEMENT == '' || bundle_local.vars.ELEMENT == null) {
        bundle_local.vars.ELEMENTID = arr[0].ELEMENTID
        bundle_local.vars.ELEMENT = arr[0].ELEMENT;
      }

      if (callback01 != null) {
        callback01(bundle_local.elements, inBundle)
      } else {
        return bundle_local.elements
      }
    }
  }

  getForms() {
    return this.bundle_local.forms
  }

  getActiveElement(callback01?: Function, inBundle?) {

    if (callback01 != null) {
      callback01(this.bundle_local.vars.ELEMENT, inBundle)
    } else {
      return this.bundle_local.vars.ELEMENT
    }

  }

  getActiveElement_record() {

    for (let i = 0; i < this.bundle_local.elements.length; i++) {
      if (this.bundle_local.elements[i].ELEMENTID == this.bundle_local.vars.ELEMENTID) {
        return this.bundle_local.elements[i];
      }
    }
  }

  getActiveElementId() {
    return this.bundle_local.vars.ELEMENTID
  }

  setActiveElement_withElementId(ELEMENTID) {

    this.bundle_local.vars.ELEMENTID = ELEMENTID;

    for (let i = 0; i < this.bundle_local.elements.length; i++) {
      if (this.bundle_local.elements[i].ELEMENTID == this.bundle_local.vars.ELEMENTID) {

        this.bundle_local.vars.ELEMENT = this.bundle_local.elements[i].ELEMENT;
      }
    }
  }

  setActiveElement_withElement(ELEMENT) {
    this.bundle_local.vars.ELEMENT = ELEMENT;
  }

  NG_LL_model_AR_metric_graph_data_get(callback01?: Function, inBundle?, obj?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_metric_graph_data_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      callback01(arr, inBundle, obj);

    }
  }

  NG_LL_model_AR_benchmarkGroupTypes_get(callback01?: Function, inBundle?) {

   var DESCRIPTION = '';

    for (let i = 0; i < this.bundle_local.elements.length; i++) {
      if (this.bundle_local.elements[i].ELEMENTID == this.bundle_local.vars.ELEMENTID) {
        DESCRIPTION = this.bundle_local.elements[i].DESCRIPTION
      }
    }

    this.bundle_local.vars.ac = "NG_LL_model_AR_benchmarkGroupTypes_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)      

      var foundForm: any = [];
      var forms: any = [];

      let indx = 0;

      for (let i = 0; i < arr.length; i++) {

        arr[i].DESCRIPTION = arr[i].DESCRIPTION + ": " + DESCRIPTION;

        if (foundForm.indexOf(arr[i].FORMNAME) == -1) {
          foundForm.push(arr[i].FORMNAME);

          forms.push({ FORMNAME: arr[i].FORMNAME, FORMID: arr[i].FORMID, rowY: indx * 20 });

          indx++
        }

        arr[i].rowY = indx * 20

        indx++
      }

      bundle_local.metricGroups = arr
           
      callback01(arr, forms, inBundle)
      
    }
  }

  getMetricGroups(callback01?: Function, bundle?, obj?) {    
    callback01(this.bundle_local.metricGroups, bundle, obj);
  }

  NG_LL_model_AR_metric_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_metric_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      // console.log(arr)

      callback01(arr, inBundle)
     
    }
  }

  NG_LL_model_AR_leadLenders_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_leadLenders_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

     ///console.log(arr)

      callback01(arr, inBundle)

    }
  }

  setExportFileName(address) {

    let loan = this.getActiveCategory()
    

    this.bundle_local.exportFileName = loan.FOLDERNAME + "_" + address;
  }

  getExportFileName() {
    return this.bundle_local.exportFileName;
  }

}
