import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { stringToNumber } from "../utility";
import { javaDate_to_shortDate } from "../utility-date";
import { YYYYMMDD_to_shortDate } from "../utility-date";

@Component({
  selector: 'll-model-report-bbc-one',
  templateUrl: './ll-model-report-bbc-one.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlModelReportBbcOneComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_report_bbc_one') private mainDiv: ElementRef;

  constructor(
    private LlModelsService: LlModelsService,
  ) { }

  bundle = {
    showDisplay: false,
    logo: "",
    imageSource: "",
    imageHeight: 25,
    daysBack: 0,
    REPORTFORMCONTEXTID: 0,
    FOLDERTYPE: "",
    LlModelsService: this.LlModelsService,
    elements: [],
    forms: [],
    locations: [],
    records: [],
    loan: {},
    date_now: "",
    date_calc: "",
    BBC_label: "",
    COMPANYNAME: "",
    MODELNAME: "",
    LOANLANGUAGE: "",
    parentW: 0,
    parentH: 0,
    displayX: 0,
    displayY: 0,
    displayW: 0,
    displayH: 0,
    pageW: 0,
    pageH: 0,
    mainColW_1: 0,
    mainColW_2: 0,
    mainColW_2_1: 50,
    Tooltip01: "",
  }

  ngOnDestroy() {}

  ngOnInit() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {

    setTimeout(() => {
      let activeReport = this.LlModelsService.getActiveReport();

      this.bundle.BBC_label = "Borrowing Base Certificate - Averaged Values"

      if (activeReport.IDENTIFIER01.toLowerCase() == 'day') {
        this.bundle.BBC_label = "Borrowing Base Certificate - Daily Values"
      }

      var n = new Date();
      this.bundle.date_now = javaDate_to_shortDate(n);

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      this.bundle.displayW = this.bundle.parentW - (this.bundle.displayX * 2) - 15
      this.bundle.displayH = this.bundle.parentH - this.bundle.displayY - 40

      this.bundle.pageW = this.bundle.displayW - 40;

      if (this.bundle.pageW > 800) {
        this.bundle.pageW = 800;
      }

      this.bundle.pageH = this.bundle.displayH;
      this.bundle.mainColW_1 = (this.bundle.pageW * .6) - 30;
      this.bundle.mainColW_2 = this.bundle.pageW - this.bundle.mainColW_1 - 20;

      this.getData();
    });
  }

  getData() {

    this.LlModelsService.NG_LL_model_AR_formElements_get(done01, this.bundle,);

    function done01(elements, bundle) {
      bundle.elements = elements;
      bundle.forms = bundle.LlModelsService.getForms();
      bundle.LlModelsService.NG_LL_model_AR_reports_BBC_locations_get(done02, bundle);
    }

    function done02(arr, bundle) {
      bundle.locations = arr;
      bundle.LlModelsService.getReportData(done03, bundle);   
    }

    function done03(arr, bundle) {

      //console.log(arr)

      bundle.records = arr;

      bundle.COMPANYNAME = bundle.records[0].COMPANYNAME
      bundle.MODELNAME = bundle.records[0].MODELNAME
      bundle.LOANLANGUAGE = bundle.records[0].LOANLANGUAGE

      bundle.logo = bundle.records[0].BBC_LOGO
      bundle.imageSource = "assets/" + bundle.logo
      bundle.imageHeight = bundle.records[0].BBC_LOGOHEIGHT;

      bundle.date_calc = YYYYMMDD_to_shortDate(String(bundle.records[0].PERIODENDDATE_NUM));

      for (let i = 0; i < bundle.records.length; i++) {
        for (let j = 0; j < bundle.elements.length; j++) {

          if (Number.isFinite(bundle.records[i][bundle.elements[j].ELEMENT]) == true) {

            let reformatedValue = stringToNumber(bundle.records[i][bundle.elements[j].ELEMENT], bundle.elements[j].DISPLAYFORMAT, bundle.elements[j].DECIMALPLACES);

            ///let reformatedValue = formatGraphNumber(bundle.records[i][bundle.elements[j].ELEMENT], bundle.elements[j].DISPLAYFORMAT);
            bundle.records[i][bundle.elements[j].ELEMENT] = reformatedValue;

          }

         bundle.elements[j].input = false;

         let element = bundle.elements[j].ELEMENT;

          if (element == 'LOAN_UNPOSTEDCASH'
            || element == 'LOAN_BEGININGBALANCE_AMT'
            || element == 'LOAN_CASHAPPLIED_AMT'
            || element == 'LOAN_INTEREST_AMT'
            || element == 'LOAN_FEES_AMT'
            || element == 'LOAN_ADVANCEREQUEST_AMT'
            || element == 'LOAN_MANUALADUSTMENT01_AMT'
            || element == 'LOAN_MANUALADUSTMENT02_AMT'
            || element == 'LOAN_MANUALADUSTMENT03_AMT'
            || element == 'LOAN_MANUALADUSTMENT04_AMT'
          ) {

            bundle.elements[j].input = true;

          }
        }
      }

      setTimeout(() => {
        bundle.showDisplay = true;
      });
    }
  }

  calculations(event): void {

    //console.log(event)


    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = target.id;

    let val = (<HTMLInputElement>document.getElementById(idAttr)).value;

    //console.log(val)

    //console.log(document.getElementById(idAttr))

    //this.bundle.records[0][idAttr] = (<HTMLInputElement>document.getElementById(idAttr)).value;

    this.bundle.records[0][idAttr] = val;

    this.calculate();
  }

  calculate() {

    var available = Number(String(this.bundle.records[0].COMBO_AVAILABLE).replace(/,/g, ""))
    var beginingBal = Number(String(this.bundle.records[0].LOAN_BEGININGBALANCE_AMT).replace(/,/g, ""))
    var commitment = Number(String(this.bundle.records[0].LIMIT_COMMITMENT_AMT).replace(/,/g, ""))
    var advance = Number(String(this.bundle.records[0].LOAN_ADVANCEREQUEST_AMT).replace(/,/g, ""))

    var manual_adj_01 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT01_AMT).replace(/,/g, ""))
    var manual_adj_02 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT02_AMT).replace(/,/g, ""))
    var manual_adj_03 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT03_AMT).replace(/,/g, ""))
    var manual_adj_04 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT04_AMT).replace(/,/g, ""))

    let borrowbase = available + manual_adj_01 + manual_adj_02 + manual_adj_03 + manual_adj_04;
    var loanAvailable = borrowbase;///available - unposted;     

    if (commitment < loanAvailable) {
      loanAvailable = commitment
    }

    let availableToAdvance = loanAvailable - beginingBal;
    let endingBalance = beginingBal + advance
    let excessAvailable = loanAvailable - endingBalance;///commitment - endingBalance;

    this.bundle.records[0].LOAN_BORROWBASE_AMT = stringToNumber(borrowbase, 'c', 0); 
    this.bundle.records[0].LOAN_AVAILABLETOADVANCE_AMT = stringToNumber(availableToAdvance, 'c', 0);
    this.bundle.records[0].LOAN_ENDINGBALANCE_AMT = stringToNumber(endingBalance, 'c', 0);
    this.bundle.records[0].LOAN_EXCESSAVAILABLE_AMT = stringToNumber(excessAvailable, 'c', 0);
  }


  getBBCPDF() {

    this.bundle.records[0].LOAN_UNPOSTEDCASH = stringToNumber(this.bundle.records[0].LOAN_UNPOSTEDCASH, 'c', 0);
    this.bundle.records[0].LOAN_LOANBALANCEPAST_AMT = stringToNumber(this.bundle.records[0].LOAN_LOANBALANCEPAST_AMT, 'c', 0);
    this.bundle.records[0].LOAN_CASHAPPLIED_AMT = stringToNumber(this.bundle.records[0].LOAN_CASHAPPLIED_AMT, 'c', 0);
    this.bundle.records[0].LOAN_FEES_AMT = stringToNumber(this.bundle.records[0].LOAN_FEES_AMT, 'c', 0);
    this.bundle.records[0].LOAN_INTEREST_AMT = stringToNumber(this.bundle.records[0].LOAN_INTEREST_AMT, 'c', 0);
    this.bundle.records[0].LOAN_ADVANCEREQUEST_AMT = stringToNumber(this.bundle.records[0].LOAN_ADVANCEREQUEST_AMT, 'c', 0);
    this.bundle.records[0].LOAN_BEGININGBALANCE_AMT = stringToNumber(this.bundle.records[0].LOAN_BEGININGBALANCE_AMT, 'c', 0);
    this.bundle.records[0].LOAN_MANUALADUSTMENT01_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT01_AMT, 'c', 0);
    this.bundle.records[0].LOAN_MANUALADUSTMENT02_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT02_AMT, 'c', 0);
    this.bundle.records[0].LOAN_MANUALADUSTMENT03_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT03_AMT, 'c', 0);
    this.bundle.records[0].LOAN_MANUALADUSTMENT04_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT04_AMT, 'c', 0);

    this.LlModelsService.NG_LL_model_AR_reports_BBC_PDF_one(this.bundle.records[0], this.bundle)
  }

}
