import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';


@Component({
  selector: 'll-widget-mlp-each',
  templateUrl: './ll-widget-mlp-each.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlWidgetMlpEachComponent implements OnInit {

  @ViewChild('metricHeader') metricHeader: ElementRef;


  constructor(
    public LlInfoRequestsService: LlInfoRequestsService,
  ) { }


  bundle = {

    dataEntry: false,
    record: [],
    choices: [],
    origionalVal: "",
    LlInfoRequestsService: this.LlInfoRequestsService,
    STUDYQUESTIONID: "",
    objW: 0,    
    QUESTIONRESULT_USER: '',
    QUESTIONRESULT_SPONSOR: '',
    Tooltip01: 'not Found',
  }

  ngOnInit() {
  }

  eventSubscription_renewQuestion: any;

  ngOnDestroy() {
    this.eventSubscription_renewQuestion.unsubscribe();
  }

  ngAfterViewInit() {
    this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

      if (Number(this.bundle.STUDYQUESTIONID) == Number(STUDYQUESTIONID)) {
        this.setVars();
      }

   });

    this.setVars();

  }

  setVars() {

    var id = this.metricHeader.nativeElement.parentElement.id;

    this.bundle.STUDYQUESTIONID = String(id.slice(0, id.lastIndexOf("|")).replace(/\s/g, ""));
    this.bundle.objW = Number(String(id.slice(id.lastIndexOf("|") + 1).replace(/\s/g, "")));

    let record = this.bundle.LlInfoRequestsService.getSelectStudyQuestion(this.bundle.STUDYQUESTIONID);
    this.bundle.STUDYQUESTIONID = record.STUDYQUESTIONID;

    this.bundle.Tooltip01 = "Data entry is disabled for this question.  Either the question has been locked or signed, is read only, deleted (red), or you do not have data entry permission.";


    setTimeout(() => {
     
      this.bundle.dataEntry = record.dataEntry;

      let choices = [];

      choices = this.bundle.LlInfoRequestsService.getQuestionChoices(this.bundle);
   

      this.bundle.origionalVal = record.QUESTIONRESULT_USER;

      this.setSelect(record.QUESTIONRESULT_USER, choices)
    });

  }

  setSelect(choice, choices) {

    for (let i = 0; i < choices.length; i++) {
      choices[i].SELECTED = false;

      if (choice == choices[i].QUESTIONRESULT_USER) {
        choices[i].SELECTED = true;     
      }
    }

    setTimeout(() => {
      this.bundle.choices = choices;
    });

  }

  onChange_jqxCheckBox(event) {

    let checked = event.args.checked;
    let choice = event.currentTarget.innerText;

    //console.log(checked)
    //console.log(choice)
    //console.log(event)

    let go = false;

    if (checked == true) {
      go = true
    }

    if (checked == false && choice == this.bundle.origionalVal) {
      go = true
    }

    if (go == true) {

      /// this.setSelect(choice, this.bundle.choices)

      for (let i = 0; i < this.bundle.choices.length; i++) {

        if (this.bundle.dataEntry == true
          && this.bundle.choices[i].QUESTIONRESULT_USER == choice) {

          this.bundle.QUESTIONRESULT_USER = this.bundle.choices[i].QUESTIONRESULT_USER;

          if (checked == false) {
            this.bundle.QUESTIONRESULT_USER = "";
          }

          //console.log("hit server")      

          this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID);
          ///this.LlInfoRequestsService.NG_LL_infoReq_subjectQuestions_updateValue("STD", done01, this.bundle);
          this.LlInfoRequestsService.NG_LL_infoReq_subjectQuestions_updateValue("STD", this.bundle)
          //function done01(bundle) {
            //do nothing
          //}
        }
      }
    }
  }
}
