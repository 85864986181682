import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlUtilityService } from '../ll-utility.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-admin-email-templates-sp',
  templateUrl: './ll-admin-email-templates-sp.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlAdminEmailTemplatesSpComponent {

  @ViewChild('mainDiv_ll_admin_email_templates_sp') private mainDiv: ElementRef;
  @ViewChild('vertTab_ll_admin_email_templates_sp') private vertTab: ElementRef;
  @ViewChild('horzTabs_ll_admin_email_templates_sp') public horzTabs: ElementRef;
  @ViewChild('scrollpane_ll_admin_email_templates_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search') private text_search: jqxTextAreaComponent;
  @ViewChild('popover_addEmailTemplate') private popover_addEmailTemplate: jqxPopoverComponent;
  @ViewChild('text_emailTemplateName') private text_emailTemplateName: jqxTextAreaComponent;

  constructor(
    private LlUtilityService: LlUtilityService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "Email Templates",
    prefix: "ET",
    selectedId: -1,
    selectedId_ondeck: -1,
    showObj: true,//false,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canEdit: false,
    canFilter: false,
    editMode: false,
    searchText: "",
    LlUtilityService: this.LlUtilityService,
    popover_addEmailTemplate: {},
    getData: {},
    records: [],
    origionalRecords: [],
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,
    topOffset: 0,
    spY: 0,
    spW: 200,
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: 40,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    activeHorzTab: '',
    hTabW: 100,
    hTabH: 25,
    hTabIndent: 30,
    hTabIndx: 0,
    horzTabs: [],
    horzTabs_02: [],
    canvasHT: {},
    ctxHT: {},
    Tooltip01: 'not Found',
    newMsg: '',
  }

  ngOnInit() { }

  ngOnDestroy() { }

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    this.setVars();
  }

  setVars() {

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    this.bundle.horzTabs.splice(0);
    this.bundle.horzTabs.push({ lbl: "View" });

    if (this.LlPermissionsService.check("editEmailTemplates")) {
      this.bundle.horzTabs.push({ lbl: "Edit" });
      this.bundle.horzTabs.push({ lbl: "HTML" });
    }
    
    this.bundle.activeHorzTab = this.bundle.horzTabs[0].lbl;

    setTimeout(() => {

      this.bundle.canAdd = this.LlPermissionsService.check("editEmailTemplates");

      this.LlScrollpaneService.standardDimentions(this.bundle);

      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH - 0;
      this.bundle.ctx = canvasEl.getContext('2d');

      var canvasHT: HTMLCanvasElement = this.horzTabs.nativeElement;
      this.bundle.canvasHT = canvasHT
      this.bundle.ctxHT = canvasHT.getContext('2d');

      setTimeout(() => {

        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.bundle.remainingY = this.bundle.vertLineY + 1
        this.bundle.remainingH = this.bundle.vertLineH - this.bundle.remainingY;

        this.getData('new', this.bundle);

      });
    });
  }

  getData(type, bundle) {

    if (type == 'new') {
      this.LlUtilityService.NG_utility_email_templates_get(done01, bundle);
    } else {
      this.LlUtilityService.getExistingEmailTemplates(done01, bundle);
    }

    function done01(records, bundle) {

      bundle.records = records;
      bundle.origionalRecords = records;

      let selectedId_initial = bundle.LlUtilityService.getActiveSYSTEMEMAILTEMPLATEID();

      ///console.log("selectedId_initial:" + selectedId_initial)

      setTimeout(() => {

        bundle.showObj = true;

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });
      });
    }
  }

  select(event) {

    //console.log("select")

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.editMode = false;
      this.bundle.showRemaining = false;

      setTimeout(() => {

        this.bundle.selectedId = this.bundle.selectedId_ondeck;
        this.LlUtilityService.setActiveSYSTEMEMAILTEMPLATEID(this.bundle.selectedId);

        this.LlScrollpaneService.paintHorzTab(horzTabSelected, this.bundle.horzTabs, this.bundle.canvasHT, this.bundle.ctxHT, this.bundle);

        function horzTabSelected(indx) { }


        this.bundle.showRemaining = true;
      });
    }
  }

  noAction() {
    //needed HTML buttons point to this 
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        if (this.bundle.records.length > 0) {
          this.bundle.selectedId = this.bundle.records[0].FOLDERID;

          this.LlUtilityService.setActiveSYSTEMEMAILTEMPLATEID(this.bundle.selectedId);

        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].FOLDERID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].FOLDERID;

        this.LlUtilityService.setActiveSYSTEMEMAILTEMPLATEID(this.bundle.selectedId);

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {

    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }

  add() {

    this.bundle.newMsg = ""

    if (this.text_emailTemplateName.val() != "") {

      this.bundle.popover_addEmailTemplate = this.popover_addEmailTemplate;
      this.bundle.getData = this.getData;

      this.LlUtilityService.NG_utility_email_template_add(this.returnedMessage, this.text_emailTemplateName.val(), this.bundle);

    }
  }

  returnedMessage(msg, bundle) {

    ///console.log("msg:"+msg)

    if (msg == '0') {
      bundle.popover_addEmailTemplate.close();
      bundle.getData('exisiting', bundle);

    } else {
      bundle.newMsg = msg
    }
  }

  edit(event) {



    this.bundle.editMode = false;
    this.bundle.showRemaining = false;

    //let FOLDERID = this.LlScrollpaneService.get2alphaId(event);

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    setTimeout(() => {
      this.bundle.selectedId = this.bundle.selectedId_ondeck;

      this.LlUtilityService.setActiveSYSTEMEMAILTEMPLATEID(this.bundle.selectedId);
      //this.LlModelsService.getActiveCategory(done01, this.bundle);

      //this.bundle.editMode = true;


      //function done01(record, bundle) {
      //bundle.LlCompaniesService.setActive(record.COMPANYID);

      //setTimeout(() => {
      //bundle.editMode = true;
      //});
      //}

    });



  }

  goBack() {
    this.bundle.editMode = false;
    this.bundle.showRemaining = true;
  }
}



