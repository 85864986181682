import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';

@Component({
  selector: 'll-info-request-entry-question',
  templateUrl: './ll-info-request-entry-question.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestEntryQuestionComponent {

  //@ViewChild('textAreaReference') private myInput: jqxTextAreaComponent;
  //@ViewChild('inputField') private inputField: ElementRef;
  @ViewChild('metricHeader') metricHeader: ElementRef;


  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
  ) { }

  bundle = {
    showObj: false,
    LlInfoRequestsService: this.LlInfoRequestsService,
    STUDYQUESTIONID: 0,
    QUESTIONINPUTTYPE: '',
    QUESTIONNAME: '',
    QUESTIONINSTRUCTIONS:'',
    COUNT: "",
    CIRCLEIMG_QUESTION: "",
    parentW:0,
    objW: 0,
    col01W: 50, //status circle and row counter
    col02W: 0, //question language
  }



  ngOnInit() { }

  eventSubscription_renewQuestion: any;

  ngOnDestroy() {
    this.eventSubscription_renewQuestion.unsubscribe();
  }

  ngAfterViewInit() {

    this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

      if (this.bundle.STUDYQUESTIONID == STUDYQUESTIONID) {
        this.setVars();
      }

    });

    this.setVars();

  }

  setVars() {

    this.bundle.STUDYQUESTIONID = this.metricHeader.nativeElement.parentElement.id;
    let record = this.LlInfoRequestsService.getSelectStudyQuestion(this.bundle.STUDYQUESTIONID);

    ///console.log(record)

    setTimeout(() => {

      this.bundle.objW = this.metricHeader.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.col02W = this.bundle.objW - this.bundle.col01W - 40; 

      this.bundle.COUNT = record.COUNT;
      this.bundle.CIRCLEIMG_QUESTION = record.CIRCLEIMG_QUESTION;
      this.bundle.QUESTIONINPUTTYPE = record.QUESTIONINPUTTYPE;
      this.bundle.QUESTIONNAME = record.QUESTIONNAME;
      this.bundle.QUESTIONINSTRUCTIONS = record.QUESTIONINSTRUCTIONS;
    });


  }


}

