import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-model-classes-sp',
  templateUrl: './ll-model-classes-sp.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelClassesSpComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_classes_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_model_classes_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_model_classes_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search') text_search: jqxTextAreaComponent;
  //@ViewChild('horzTabs_ll_model_classes_sp', { static: false }) public horzTabs: ElementRef;
  @ViewChild('popover_classCo') private popover_classCo: jqxPopoverComponent;
  @ViewChild('text_className') text_className: jqxTextAreaComponent;


  constructor(
    private LlModelsService: LlModelsService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "Payer Class",
    prefix: "PC",
    selectedId: 0,
    selectedId_ondeck: 0,
    //showObj: false,
    showObj: true,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canEdit: false,
    canFilter: true,
    searchText: "",
    activeModelFolderName:'edit',
    LlModelsService: this.LlModelsService,
    records: [],
    origionalRecords: [],
    ///groups: [],
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,
    topOffset: 0,
    spY: 0,
    spW: 175,///width of column
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    hTabW: 100,
    hTabH: 25,
    hTabIndx: 0,
    horzTabs: [],
    canvasHT: {},
    ctxHT: {},
  }

  ngOnInit() { }

  eventSubscription_newView: any;

  ngOnDestroy() {
    this.eventSubscription_newView.unsubscribe();
  }

  ngAfterViewInit() {
    
    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    this.eventSubscription_newView = this.LlModelsService.ll_model_functions_sp_GROUP_newFOLDERNAME.subscribe(selectedFOLDERNAME => {

      this.bundle.showRemaining = false;

      this.bundle.activeModelFolderName = selectedFOLDERNAME;

      setTimeout(() => {
        this.bundle.showRemaining = true;
      });

    });

    this.setVars();
  }

  setVars() {

    this.bundle.activeModelFolderName = this.mainDiv.nativeElement.parentElement.id;

    if (this.bundle.activeModelFolderName == 'edit') {
      this.bundle.canAdd = this.LlPermissionsService.check("addEditClasses");
    }     

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.LlScrollpaneService.standardDimentions(this.bundle);

      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH - 0;
      this.bundle.ctx = canvasEl.getContext('2d');

      setTimeout(() => {

        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.bundle.remainingY = this.bundle.vertLineY + 1
        this.bundle.remainingH = this.bundle.vertLineH - this.bundle.remainingY;

        this.getData();
      });
    });
  }

  getData() {

    
    this.LlModelsService.NG_LL_model_AR_payerClasses_get(done01, this.bundle);
    
    function done01(records, bundle) {

      bundle.records = records;
      bundle.origionalRecords = records;

      let selectedId_initial = bundle.LlModelsService.getActivePayerClassId();

      setTimeout(() => {

        bundle.showObj = true;

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });
      });
    }
  }

  select(event) {

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.showRemaining = false;

      setTimeout(() => {

        this.bundle.selectedId = this.bundle.selectedId_ondeck;

        this.LlModelsService.setActivePayerClass(this.bundle.selectedId);

        this.bundle.showRemaining = true;
      });
    }
  }

  noAction() {
    //needed HTML buttons point to this
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        if (this.bundle.records.length > 0) {
          this.bundle.selectedId = this.bundle.records[0].FOLDERID;

          this.LlModelsService.setActivePayerClass(this.bundle.selectedId);

        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].FOLDERID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].FOLDERID;

        this.LlModelsService.setActivePayerClass(this.bundle.selectedId);

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {


    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }

  add() {

    if (this.text_className.val() != "") {

      this.popover_classCo.close();

      this.LlModelsService.NG_LL_model_AR_payerClasses_addEdit('ADD', this.text_className.val(), this.bundle);
    }
  }

}

