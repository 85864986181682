import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlModelsService } from '../ll-models.service';
import { LlCompaniesService } from '../ll-companies.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-models-sp',
  templateUrl: './ll-models-sp.component.html',
  styleUrls: ['../cssMain.css'],
  styles: ['.jqx-panel .jqx-widget-content { background-color: transparent;}'],
  encapsulation: ViewEncapsulation.None
})

export class LlModelsSpComponent implements OnInit {

  @ViewChild('mainDiv_ll_models_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_models_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_models_sp') private SP: jqxPanelComponent;  
  @ViewChild('popover_search_ll_models_sp') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search_ll_models_sp') text_search: jqxTextAreaComponent;

  constructor(
    private LlScrollpaneService: LlScrollpaneService,
    private LlModelsService: LlModelsService,
    private LlCompaniesService: LlCompaniesService,
    private LlPermissionsService : LlPermissionsService ,
    private cd: ChangeDetectorRef,
  ) { }

  bundle = {
    lable: "Categories",
    prefix: "MD",
    selectedId: -1,
    selectedId_ondeck:-1,
    showObj: true,
    showRemaining: false,
    editMode: false,
    scrolling: false,
    canEdit: false,
    canAdd: false,
    canFilter: true,
    searchText: "",
    STATUS: 'A',
    INFOREQUEST_ONLY:false,
    LlModelsService: this.LlModelsService,
    LlCompaniesService: this.LlCompaniesService,
    cd: this.cd,
    records: [],
    origionalRecords: [],
    groups: [],
    parentW: 0,
    parentH:0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,
    topOffset:0,
    spY:0,
    spW: 150,
    spH:0,
    spB: 0,
    lineWidth:1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH:18,
    Tooltip01: 'not Found',    
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        //setTimeout(() => {
          let value = this.SP.getVScrollPosition();

          if (value != this.bundle.SPYPosition) {
            this.bundle.SPYPosition = value;

            this.bundle.scrolling = true;

            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        //});
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        //setTimeout(() => {
          let value = this.SP.getVScrollPosition();

          if (value != this.bundle.SPYPosition) {
            this.bundle.SPYPosition = value;

            this.bundle.scrolling = true;

            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        //});
      }
      );

    this.setVars();
  }
   
  setVars() {

    setTimeout(() => {

      if (this.LlPermissionsService.check("addEditCompany")
        || this.LlPermissionsService.check("viewModels")
        || this.LlPermissionsService.check("EditModels")
        || this.LlPermissionsService.check("addEditUser")
        ) {   
        this.bundle.canEdit = true;
      }

        

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
         
      setTimeout(() => {

        this.LlScrollpaneService.standardDimentions(this.bundle);

       //dimention adjustments just for this SP..sets baseline heights
        this.bundle.spH = this.bundle.objH - this.bundle.spY - 90;//80;
        this.bundle.vertLineH = this.bundle.objH - (this.bundle.vertLineW * 2) - 10;
    

        var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
        canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
        canvasEl.height = this.bundle.vertLineH;

        this.bundle.ctx = canvasEl.getContext('2d');

        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.getData();
      });
    });
  }

  getData() {

    this.LlModelsService.NG_LL_user_models_get(done01, this.bundle);

    function done01(records, bundle) {

      ///console.log(records)
      
      bundle.records = records;
      bundle.origionalRecords = records;
      bundle.groups.splice(0);

      for (let i = 0; i < bundle.records.length; i++) {
        if (bundle.groups.indexOf(bundle.records[i].GROUP) == -1) {
          bundle.groups.push(bundle.records[i].GROUP);
        }
      }

      //console.log(bundle.groups)

      let selectedId_initial = bundle.LlModelsService.getActiveId();

      //console.log("selectedId_initial: " + selectedId_initial)

      ///bundle.showObj = true;

      setTimeout(() => {

        bundle.showObj = true;
        bundle.cd.detectChanges();

        //console.log("bundle.showObj: " + bundle.showObj)

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });

      },0);
    }
  }

  select(event) {

    //console.log("select")

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    //console.log("this.bundle.scrolling: "+this.bundle.scrolling)


    if (this.bundle.scrolling == true) {
      this.bundle.scrolling = false;

    } else {

      this.bundle.editMode = false;
      this.bundle.showRemaining = false;

     


      setTimeout(() => {
        this.bundle.selectedId = this.bundle.selectedId_ondeck;

        this.LlModelsService.setActiveId(this.bundle.selectedId);
        
        this.bundle.INFOREQUEST_ONLY = false;
        var model = this.LlModelsService.getActiveCategory();

        ///console.log(model)

        if (model.ARDATAFEEDYESNO == "No" && model.LTCCMSSCORINGYESNO == "No" && model.INFOREQUESTSYESNO == "Yes") {
          this.bundle.INFOREQUEST_ONLY = true;
        }

        setTimeout(() => {
          this.bundle.showRemaining = true;
        });
      });
    }
  }

  edit(event) {

    this.bundle.editMode = false;
    this.bundle.showRemaining = false;

    //let FOLDERID = this.LlScrollpaneService.get2alphaId(event);

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    setTimeout(() => {
      this.bundle.selectedId = this.bundle.selectedId_ondeck;

      this.LlModelsService.setActiveId(this.bundle.selectedId);
      this.LlModelsService.getActiveCategory(done01, this.bundle);

      function done01(record, bundle) {
        bundle.LlCompaniesService.setActive(record.COMPANYID);

        setTimeout(() => {
          bundle.editMode = true;
        });
      }
      
    });



  }

  goBack() {
    this.bundle.editMode = false;
    this.bundle.showRemaining = true;
  }

  noAction() {
    //needed HTML buttons point to this 
  }

  selectView(targetView) {
    //this.bundle.CONFIG = targetView.toLowerCase();
    //this.UserService.setUserPreference("TraditionalOrTile_config", targetView, 1, "edit");
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }
  
  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);
        
        if (this.bundle.records.length > 0) {

          this.bundle.selectedId = this.bundle.records[0].FOLDERID;

          this.LlModelsService.setActiveId(this.bundle.selectedId);

        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].CATID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].CATID;
        ///this.LlModelsService.setActiveCategory(this.bundle.selectedId, 'SP');

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {


    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }

  changeToActive() {
    this.bundle.STATUS = 'A';
    //this.LlModelsService.setModelSPStatus(this.bundle.STATUS);
    //this.SP.clearcontent();
    //this.bundle.showObj = false;
    //this.getData()
    this.setVars();

    //this.getNew()
  }

  changeToInactive() {
    this.bundle.STATUS = 'N';
    //this.LlModelsService.setModelSPStatus(this.bundle.STATUS);
    //this.SP.clearcontent();

    //this.bundle.showObj = false;
    //this.getData()
    this.setVars();

    //this.getNew()

  }

  getNew() {
    ///this.bundle.showObj = false;


    this.LlModelsService.NG_LL_user_models_get(done01, this.bundle);

    function done01(records, bundle) {

      console.log(records)

      bundle.records = records;
      bundle.origionalRecords = records;

      let selectedId_initial = bundle.LlModelsService.getActiveId();

      console.log("selectedId_initial: " + selectedId_initial)

      setTimeout(() => {

        //bundle.showObj = true;

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });

      }, 0);

    }
  }

}
