import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlContactsService } from '../ll-contacts.service';
import { LlCompaniesService } from '../ll-companies.service';
import { LlPermissionsService } from '../ll-permissions.service';

import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { jqxDropDownListComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownlist";

@Component({
  selector: 'll-contact-info',
  templateUrl: './ll-contact-info.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlContactInfoComponent implements OnInit {

  @ViewChild('mainDiv_ll_contact_info') mainDiv: ElementRef;
  @ViewChild('popover_resetPassword') private popover_resetPassword: jqxPopoverComponent;
  @ViewChild('text_CONTACTEMAIL') text_CONTACTEMAIL: jqxTextAreaComponent;
  @ViewChild('text_CONTACTFIRST') text_CONTACTFIRST: jqxTextAreaComponent;
  @ViewChild('text_CONTACTLAST') text_CONTACTLAST: jqxTextAreaComponent;
  @ViewChild('dropDownList_company') dropDownList_company: jqxDropDownListComponent;

  constructor(
    private LlPermissionsService: LlPermissionsService,
    private LlContactsService: LlContactsService,
    private LlCompaniesService: LlCompaniesService,
  ) { }

  bundle = {
    showDisplay: false,
    topMsg01:'User Settings',
    location: "",
    editUserMsg:'',
    SYSTEMPRODUCTID: 0,
    STUDYUPLOADID: 0,
    companyIndex: 9999,
    DOC: {},
    showContactRoles: false,
    LlContactsService: this.LlContactsService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    textEntryX: 75,
    textEntryW: 0,
    companies: [],
    companyChoices: [],
    fields: [],
    record: {},
    contact: {},
    contactId: 0,
    panelH: 250,
    panelW: 0,
    //headerH: 200,
    //bodyH: 0,
    showRqrMsg: false,

    COMPANYID_ACTIVE: 0,
    CONTACTEMAIL: "",
    CONTACTFIRST: "",
    CONTACTLAST: "",
    SENTINVITEYESNO: "",
    dropDownList_company: {},// this.dropDownList_company,
  }

  ngOnDestroy() { }
  ngOnInit() { }

  ngAfterViewInit(): void {
    this.setVars()
  }

  setVars() {

    //console.log("setVars()")

    this.bundle.dropDownList_company = this.dropDownList_company;

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.bundle.panelW = this.bundle.parentW;
      this.bundle.panelH = this.bundle.parentH;
          
      this.bundle.textEntryW = this.bundle.parentW - 150;

      if (this.bundle.textEntryW > 300) {
        this.bundle.textEntryW = 300;
      }

      this.getData();
    });
  }

  getData() {
    this.bundle.fields.splice(0);

    this.bundle.contact = this.LlContactsService.getActiveContact();

    setTimeout(function () {

      if (this.bundle.contact != null) {

        this.bundle.contactId = this.bundle.contact.CONTACTID;
        this.bundle.SENTINVITEYESNO = this.bundle.contact.SENTINVITEYESNO;
        this.bundle.COMPANYID_ACTIVE = this.bundle.contact.COMPANYID
        this.text_CONTACTEMAIL.val(this.bundle.contact.CONTACTEMAIL);
        this.text_CONTACTFIRST.val(this.bundle.contact.CONTACTFIRST);
        this.text_CONTACTLAST.val(this.bundle.contact.CONTACTLAST);
      }

    }.bind(this), 100);

    this.LlCompaniesService.getExistingCompanies(done01, this.bundle);

    function done01(records, bundle) {

      bundle.companies = records;

      let listString = []

      bundle.companyIndex = 9999999999;

      for (var i = 0; i < records.length; i++) {

        listString.push(records[i].COMPANYNAME +" ("+records[i].COMPANYID+")");

        if (records[i].COMPANYID == bundle.contact.COMPANYID) {
          bundle.companyIndex = i
        }
      }

      setTimeout(() => {
        bundle.companyChoices = listString;
        bundle.dropDownList_company.selectIndex(bundle.companyIndex);
        bundle.showDisplay = true;
      });

      
    }
  }

  selectCompany(event) {
    this.bundle.COMPANYID_ACTIVE = this.bundle.companies[event.args.index].COMPANYID;
  }

  noAction() { }

  editContact() {

    this.bundle.editUserMsg = ""

    let validEmail = this.ValidateEmail(this.text_CONTACTEMAIL.val())

    if (validEmail == false) {
      this.bundle.editUserMsg = "No Changes made - Invalid email format"

    } else if (this.text_CONTACTFIRST.val() == "" || this.text_CONTACTLAST.val() == "") {
      this.bundle.editUserMsg = "No changes made - All fields are required"

    } else {
      this.LlContactsService.NG_LL_contact_edit(this.returnedMessage, this.text_CONTACTEMAIL.val(), this.text_CONTACTFIRST.val(), this.text_CONTACTLAST.val(), this.bundle.COMPANYID_ACTIVE, this.bundle);
    }
        
  }

  returnedMessage(msg, bundle) {

    if (msg != '') {
      bundle.editUserMsg = msg
    }
  }


  ValidateEmail(inputText) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  resetAccount() {
    this.LlContactsService.NG_LL_contact_account_reset(done01);
    this.popover_resetPassword.close();
    this.bundle.SENTINVITEYESNO = 'Yes'

    function done01() {
      ///bundle.sentStudyInviteYesNo = 'Yes'

    }
  }
}
